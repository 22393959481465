import {observer} from "mobx-react-lite";
import pc from "../controllers/properties.controller"
import TemplateTitle from "../../../template/title/TemplateTitle";
import {useTranslation} from "react-i18next";
import {Row, Col, Input, Select, Checkbox, Radio, InputNumber, Switch} from "antd";
import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import ButtonAction from "../../../template/buttons/ButtonsActions";

const ShopPropertyExample = () => {

    const optionsWithDisabled = [
        { label: 'Яблоко', value: 'Apple' },
        { label: 'Груша', value: 'Pear' },
        { label: 'Апельсин', value: 'Orange' }
    ];

    return(
        <Row className="template-examples" gutter={[20, 20]}>
            <Col span={24}>
                <p className="title">Примеры чекбоксов и радио-кнопок:</p>
            </Col>
            <Col span={12}>
                <p className="title">Чекбокс (Checkbox)</p>
                <p className="description">
                    Используется для выбора нескольких значений из нескольких вариантов. Вы можете опробовать чекбоксы в примере ниже.
                </p>

                <Checkbox.Group
                    options={optionsWithDisabled}
                    defaultValue={['Apple']}
                />
            </Col>
            <Col span={12}>
                <p className="title">Радио-кнопки (Radio)</p>
                <p className="description">
                    Используется для выбора одного состояния из нескольких вариантов. Вы можете опробовать радио кнопки в примере ниже.
                </p>

                <Radio.Group options={optionsWithDisabled} />
            </Col>
            <br/>
            <Col span={24}>
                <p className="ps">Данные примеры не имеют функционального предназначения и являются просто наглядной демонстрацией.</p>
            </Col>
        </Row>
    )
}

const ShopPropertyForm = observer(() => {

    let { propertyID } = useParams();

    useEffect(() => {
        if(propertyID && propertyID > 0) {
            pc.getProperties(propertyID)
        }
    }, [])

    return(
        <Row gutter={20}>
            <Col>
                <label className="all-label">Наименование характеристики</label>
                <Input
                    placeholder="name"
                    value={pc.property.name}
                    onChange={e => pc.setProperty("name", e.target.value)}
                    required
                />
            </Col>
            <Col>
                <label className="all-label">Тип отображения в фильтре</label>
                <Select value={pc.property.type} style={{ width: "100%" }} onChange={e => pc.setProperty("type", e)}>
                    <Select.Option value="checkbox">Чекбокс</Select.Option>
                    <Select.Option value="radio">Радио кнопка</Select.Option>
                </Select>
            </Col>
            <Col span={24}>
                <label className="all-label">Участвует в поиске</label>
                <Switch
                    size="small"
                    checked={pc.property.search > 0}
                    onChange={(e) => pc.setProperty("search", e)}
                />
            </Col>
            {propertyID && propertyID > 0 &&
                <Col span={24}>
                    <label className="all-label">Значения</label>
                    <button className="button-main" onClick={() => pc.addPropertyValue(propertyID)}>Добавить</button>
                    <br/><br/>
                    {pc.propertyVariations.map(item =>
                        <Row gutter={[10, 10]}>
                            <Col span={15}>
                                <Input
                                    placeholder="Наименование"
                                    value={item.value}
                                    onChange={e => pc.setVariant(item.id ? "id" : "ids", item[item.id ? "id" : "ids"], e.target.value)}
                                    required
                                />
                            </Col>
                            <Col span={5}>
                                <InputNumber
                                    placeholder="position"
                                    value={item.position}
                                    onChange={e => pc.setVariant("position", item[item.id ? "id" : "ids"], e, item.id ? "id" : "ids")}
                                    required
                                />
                            </Col>
                            <Col span={4}>
                                {item.id && item.id > 0 &&
                                <ButtonAction
                                    item={{
                                        type: "delete",
                                        typeButton: "button",
                                        placeholder: "Удалить",
                                        onClick: () => pc.deleteValue(item.id)
                                    }}
                                />
                                }
                            </Col>
                        </Row>
                    )}
                </Col>
            }
        </Row>
    )
})

const ShopProperty = observer(() => {

    const { t } = useTranslation();

    const breadcrumb = [
        { key: "shop", link: "/shop", value: t('orders:shop') },
        { key: "properties", link: "/shop/properties/", value: "Характеристики товаров" },
        { key: "element", link: null, value: "Редактирование характеристики" }
    ]

    const buttons = [
        { title: "Сохранить", type: "button", ref: () => pc.saveProperty() }
    ]

    return(
        <>
            <TemplateTitle
                title="Редактирование характеристики"
                breadcrumb={breadcrumb}
                buttons={buttons}
            />
            <Row gutter={[40, 20]}>
                <Col span={12}><ShopPropertyForm /></Col>
                <Col span={12}><ShopPropertyExample /></Col>
            </Row>
        </>
    )
})

export default ShopProperty