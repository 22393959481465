import React, { useState, useEffect } from 'react'

import axios from "axios";

import {Tag, Button, Space, Table, Col, Tooltip, Row, Typography, Pagination, Breadcrumb} from 'antd';
import {SettingOutlined} from "@ant-design/icons";
import {withTranslation} from "react-i18next";
import instance from "../../store/axios";

const { Title } = Typography;

function UsersList({ t }) {
    const [update, setUpdate] = useState(0);
    const [offset, setOffset] = useState(1);
    const [users, setUsers] = useState({
        list: [],
        count: 0,
        pagination: 1
    });

    useEffect(() => {
        instance.get('/users/users/?page=' + offset).then(response => {
            console.log(response);
            setUsers({
                ...users,
                list: response.data.data,
                pagination: response.data.pagination
            });
        })
    }, [update]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: t('users:name'),
            dataIndex: 'firstname',
            key: 'firstname'
        },
        {
            title: t('users:surname'),
            dataIndex: 'lastname',
            key: 'lastname',
        },
        {
            title: t('users:middle'),
            dataIndex: 'surname',
            key: 'surname',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t('users:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Tooltip title={t('users:view')}>
                    <Button type="dashed" shape="circle" href={"/users/" + record.id} icon={<SettingOutlined />} />
                </Tooltip>
            ),
        },
    ];

    const handlePagination = (value) => {
        setOffset(value);
        setUpdate(update + 1);
    };

    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Title>{t('users:listUsers')}</Title>
                </Col>
                <Col span={8}>

                </Col>
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('users:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('users:users')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Table columns={columns} dataSource={users.list} pagination={false} defaultPageSize={users.pagination[2]} />
            <br/>
            <Pagination
                defaultCurrent={users.pagination[0]}
                total={users.pagination[1]}
                showSizeChanger={false}
                onChange={handlePagination}
            />
        </>
    )
}

export default withTranslation()(UsersList);