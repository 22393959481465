import { observer } from "mobx-react-lite"
import { Row, Col, Checkbox, Input, InputNumber, Select } from "antd"
import _main from "../../controllers/_main.screen.controller"
import TypeLink from "../TypeLink"

const MainModuleButtonModal = observer(() => {

    let el = "ksbuttontext"

    return(
        <Row gutter={16}>
            <Col span={24}>
                <Checkbox
                    checked={Number(_main.selected[el].active) > 0}
                    onChange={(e) => _main.set("active", el, e.target.checked ? 1 : 0)}
                >
                    Активность
                </Checkbox>
            </Col>
            <Col span={24} className="gutter-row">
                <label htmlFor="name" className="all-label">Наименование</label>
                <Input
                    value={_main.selected[el].name}
                    id="name"
                    onChange={(e) => _main.set("name", el, e.target.value)}
                />
            </Col>
            <Col span={24} className="gutter-row">
                <label htmlFor="name" className="all-label">Текст</label>
                <Input
                    value={_main.selected[el].text}
                    id="name"
                    onChange={(e) => _main.set("text", el, e.target.value)}
                />
            </Col>
            <Col span={12} className="gutter-row">
                <label className="all-label">Текст кнопки</label>
                <Input
                    value={_main.selected[el].buttontext}
                    onChange={(e) => _main.set("buttontext", el, e.target.value)}
                />
            </Col>

            <TypeLink
                type={_main.selected[el].typelink}
                link={_main.selected[el].link}
                refs={(e) => _main.set("link", el, e)}
                refType={(e) => _main.set("typelink", el, e)}
                element={_main.selected[el].element ? _main.selected[el].element : false}
            />
        </Row>
    )
})

export default MainModuleButtonModal