import React, {useState, useEffect} from "react";
import axios from "axios";
import {Breadcrumb, InputNumber, notification, Tabs} from 'antd';
import { Row, Col, Button, Radio, Select, Checkbox, Typography, Input, Switch, TimePicker } from 'antd';
import {withTranslation} from "react-i18next";
import instance from "../../../store/axios";
import ZoneDelivery from "../../../components/map/Zone";
import zoneStore from "../../../store/shop/zoneStore";
import {observer} from "mobx-react-lite";
import interval from "../../../modules/shop/delivery/controllers/interval.controller"
import moment from "moment";

const Delivery = observer(({ t }) => {
    const [update, setUpdate] = useState(0);
    const [delivery, setDelivery] = useState({
        delivery: 0,
        points: [],
        pickup: [],
        typepay: [],
        listpay: [],
        deliveryname: [],
        yesPoints: true,
        elements: 0,
        keyService: [],
        selfDelivery: false,
        selfDeliveryName: '',
        costorder: null,
        typedelivery: 1,
        costdelivery: 0,
        viewintervaldelivery: 0,
    });

    useEffect(() => {
        interval.getInterval()

        instance.get('/shop/delivery/').then(response => {
            console.log(response.data);

            let self = false;

            if(response.data.data.delivery.delivery > 0) {
                self = true;
            }

            setDelivery({
                ...delivery,
                delivery: response.data.data.delivery.delivery,
                points: response.data.data.points,
                pickup: response.data.data.delivery.pickup,
                typepay: response.data.data.delivery.typepay,
                listpay: response.data.data.listpay,
                deliveryname: response.data.data.delivery.deliveryname,
                yesPoints: response.data.data.delivery.yesPoints,
                costorder: response.data.data.delivery.costorder,
                typedelivery: response.data.data.delivery.typedelivery,
                costdelivery: response.data.data.delivery.costdelivery,
                selfDelivery: self,
                selfDeliveryName: response.data.data.delivery.deliveryname
            });
        })
    }, [update]);

    function handleSubmit(e) {
        e.preventDefault();
        let facts = new FormData();
        console.log(delivery);
        facts.append('yesPoints', String(delivery.yesPoints));

        let pickup = [];
        delivery.pickup.map((item) =>
            pickup = [...pickup, item.id]
        );
        facts.append('pickup', pickup);

        let typepay = [];
        delivery.typepay.map((item) =>
            typepay = [...typepay, item.id]
        );
        facts.append('typepay', typepay);

        if(delivery.selfDelivery === true) {
            facts.append('delivery', delivery.selfDelivery);
        }

        facts.append('deliveryname', delivery.selfDeliveryName);

        facts.append('costdelivery', delivery.costdelivery);
        facts.append('costorder', delivery.costorder);

        facts.append('typedelivery', delivery.typedelivery);

        console.log(delivery);

        interval.onSubmit()

        instance.post('/shop/delivery/', facts).then(response => {
            setUpdate(update + 1);
            notification.success({
                placement: "bottomLeft",
                message: t('delivery:success'),
                description: t('delivery:settingSuccess'),
            });
        })
    }

    const selfDelivery = (e) => {
        let status = 0;
        if(e.checked === true) {
            status = 1;
        }
        setDelivery({
            ...delivery,
            selfDelivery: status
        });
    };

    const selectPickup = (e) => {
        let arr = [];
        e.map(item => {
            delivery.points.map(element => {
                if(element.id === item) {
                    arr = [...arr, element];
                }
            });
        });
        console.log(arr);
        setDelivery({ ...delivery, pickup: arr });
    };

    const selectPay = (e) => {
        let arr = [];
        e.map(item => {
            delivery.listpay.map(element => {
                if(element.id === item) {
                    arr = [...arr, element];
                }
            });
        });
        console.log(arr);
        setDelivery({ ...delivery, typepay: arr });
    };

    const checkDelivery = (e) => {
        console.log(e.target.checked);
        setDelivery({ ...delivery, selfDelivery: e.target.checked });
    };

    return(
        <>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <Row justify="space-around" align="middle" className="template-title">
                    <Col span={16}>
                        <Typography.Title>{t('delivery:edit')}</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="primary" disabled={zoneStore.active.id > -1 ? "disabled" : ""} htmlType="submit">{t('delivery:save')}</Button>
                    </Col>
                    <Col span={24} className="template-breadcrumb">
                        <Breadcrumb>
                            <Breadcrumb.Item href={"/"}>{t('delivery:main')}</Breadcrumb.Item>
                            <Breadcrumb.Item href={"/shop"}>{t('delivery:shop')}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t('delivery:setting')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>

                <Tabs defaultActiveKey="1" style={{ width: 100 + "%" }}>
                    <Tabs.TabPane tab={t('delivery:main')} key="main">
                        <Row justify="space-around" gutter={16} className="delivery-block">
                            <Col span={8}>
                                <h3>{t('delivery:pickup')}</h3>
                            </Col>
                            <Col span={12}>
                                <Select
                                    mode="multiple"
                                    placeholder={t('delivery:point')}
                                    onChange={selectPickup}
                                    style={{ width: '100%' }}
                                    value={delivery.pickup.map(item => item.id)}
                                >
                                    {delivery.points.map(item => (
                                        <Select.Option key={"selectPickup" + item.id} value={item.id}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={4}>
                                <div className="p-field-checkbox">
                                    <Checkbox
                                        checked={delivery.yesPoints}
                                        onChange={(e) => setDelivery({ ...delivery, yesPoints: e.target.checked })}
                                    />
                                    <label htmlFor="binary">{delivery.yesPoints ? t('delivery:pickupOn') : t('delivery:pickupOff')}</label>
                                </div>
                            </Col>
                        </Row>

                        <Row justify="space-around" align="middle" className="delivery-block">
                            <Col span={8}>
                                <h3>{t('delivery:pay')}</h3>
                            </Col>
                            <Col span={16}>
                                <Select
                                    mode="multiple"
                                    placeholder={t('delivery:selectPay')}
                                    style={{ width: '100%' }}
                                    onChange={selectPay}
                                    value={delivery.typepay.map(item => item.id)}
                                >
                                    {delivery.listpay.map(item => (
                                        <Select.Option key={"selectPay" + item.id} value={item.id}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row justify="space-around" align="middle" className="delivery-block">
                            <Col span={8}>
                                <h3>{t('delivery:myDelivery')}</h3>
                            </Col>
                            <Col span={12}>
                                <Input
                                    value={delivery.selfDeliveryName}
                                    onChange={(e) => setDelivery({ ...delivery, selfDeliveryName: e.target.value })}
                                    disabled={delivery.selfDelivery ? '' : 'disabled'}
                                />
                            </Col>
                            <Col span={4}>
                                <div className="p-field-checkbox">
                                    <Checkbox
                                        inputId="boolean"
                                        checked={delivery.selfDelivery}
                                        onChange={checkDelivery}
                                    />
                                    <label htmlFor="binary">{delivery.selfDelivery ? t('delivery:on') : t('delivery:off')}</label>
                                </div>
                            </Col>
                        </Row>

                        {delivery.selfDelivery &&
                            <>
                                <Row justify="space-around" align="middle" className="delivery-block">
                                    <Col span={8}>
                                        <h3>{t('delivery:payMyDelivery')}</h3>
                                    </Col>
                                    <Col span={6}>
                                        {delivery.typedelivery === 1 &&
                                        <>
                                            <label htmlFor="">{t('delivery:payDelivery')}</label>
                                            <Input
                                                value={delivery.costdelivery}
                                                onChange={(e) => setDelivery({ ...delivery, costdelivery: e.target.value })}
                                            />
                                        </>
                                        }
                                    </Col>
                                    <Col span={6}>
                                        <label htmlFor="">{t('delivery:minPrice')}</label>
                                        <Input
                                            value={delivery.costorder}
                                            onChange={(e) => setDelivery({ ...delivery, costorder: e.target.value })}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <div className="p-field-checkbox">
                                            <Radio.Group value={delivery.typedelivery} onChange={(e) => setDelivery({ ...delivery, typedelivery: e.target.value })}>
                                                <Radio value={1}>{t('delivery:price')}</Radio>
                                                <Radio value={2}>{t('delivery:zone')}</Radio>
                                            </Radio.Group>
                                        </div>
                                    </Col>
                                </Row>

                                <Row justify="space-around" align="middle" className="delivery-block" gutter={[20, 20]}>
                                    <Col span={24}>
                                        <h3>Интервалы доставок</h3>
                                        <Switch
                                            checkedChildren="Включено"
                                            unCheckedChildren="Выключено"
                                            onChange={e => interval.onChangeSwitch(e)}
                                            checked={interval.interval.viewintervaldelivery}
                                        />
                                    </Col>
                                    {interval.interval.viewintervaldelivery &&
                                        <>
                                            <Col span={8}>
                                                <p>Время начала доставки</p>

                                                <TimePicker
                                                    format="HH:mm"
                                                    minuteStep={10}
                                                    showNow={false}
                                                    value={moment(interval.interval.timestartdelivery, "HH:mm")}
                                                    onChange={(time, timeString) => interval.onChangeTime("timestartdelivery", time, timeString)}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <p>Время окончания доставки</p>
                                                <TimePicker
                                                    format="HH:mm"
                                                    minuteStep={10}
                                                    showNow={false}
                                                    value={moment(interval.interval.timeenddelivery, "HH:mm")}
                                                    onChange={(time, timeString) => interval.onChangeTime("timeenddelivery", time, timeString)}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <p>Интервал доставки (в минутах!)</p>
                                                <InputNumber
                                                    value={interval.interval.timeinterval}
                                                    onChange={(e) => interval.onChange(e)}
                                                />
                                            </Col>
                                        </>
                                    }
                                </Row>
                            </>
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t('delivery:zonesTab')} key="zone">
                        <ZoneDelivery />
                    </Tabs.TabPane>
                </Tabs>

            </form>
        </>
    )
});

export default withTranslation()(Delivery);