import { observer } from "mobx-react-lite"
import _main from "../../controllers/_main.screen.controller"
import { Table, Space, Tooltip, Button, Switch, Row, Col } from "antd"
import {ArrowDownOutlined, ArrowUpOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons"
import MainModuleListElements from "../MainModuleListElements";

const MainModuleSlider = observer(({ item }) => {

    const columns = [
        {
            title: 'Изображение',
            dataIndex: 'photo',
            key: 'photo',
            render: text => <img src={text} alt="" height={`84px`} />,
        },
        {
            title: 'Тип ссылки',
            dataIndex: 'typelink',
            key: 'typelink',
            render: (text, record) => {
                return <p>{_main.typeLink(text, record.link).title}</p>
            }
        },
        {
            title: 'Элемент ссылки',
            dataIndex: 'element',
            key: 'element',
            render: (text, record) => {
                return <p>#{record.id} | {record.name}</p>
            }
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title="Активность">
                        <Switch size="small" checked={record.active > 0} onChange={e => _main.editActive(record.id, "ksbaner", e, () => _main.get())} />
                    </Tooltip>

                    <Tooltip title="Изменить">
                        <Button
                            size="small" shape="circle"
                            onClick={() => _main.selectBanner(record)}
                            icon={<EditOutlined />}
                        />
                    </Tooltip>

                    <Tooltip title="Переместить выше">
                        <Button
                            shape="circle" size="small" icon={<ArrowUpOutlined />}
                            onClick={() => _main.editPosition(record.id, "ksbaner", "moveUp", "sliderid", () => _main.get())}
                        />
                    </Tooltip>
                    <Tooltip title="Переместить ниже">
                        <Button
                            shape="circle" size="small" icon={<ArrowDownOutlined />}
                            onClick={() => _main.editPosition(record.id, "ksbaner", "moveDown", "sliderid", () => _main.get())}
                        />
                    </Tooltip>
                    <Tooltip title="Удалить баннер">
                        <Button
                            shape="circle" size="small" danger icon={<DeleteOutlined />}
                            onClick={() => _main.delete("ksbaner", record.id, () => _main.get())}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return(
        <div>
            <div>
                <Row justify="space-around" align="middle" gutter={[20, 20]}>
                    <Col span={12}>
                        <h4>{item.ksslider.text}</h4>
                    </Col>
                    <Col span={12} align="right">
                        <Button
                            type="primary"
                            shape="round"
                            onClick={() => _main.selectBanner({}, item.ksslider.id)}
                        >
                            Добавить баннер
                        </Button>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={item.ksslider.baner} pagination={false} />


            </div>
        </div>
    )
})

export default MainModuleSlider