import {observer} from "mobx-react-lite"
import { Modal, Button } from 'antd'
import _main from "../controllers/_main.screen.controller"
import MainModuleButtonModal from "./button/MainModuleButtonModal";
import MainModuleBannerModal from "./slider/MainModuleBannerModal";
import MainModuleSliderModal from "./slider/MainModuleSliderModal";
import MainModuleVideoModal from "./video/MainModuleVideoModal";
import MainModuleVideoCategoryModal from "./video/MainModuleVideoCategoryModal";
import MainModuleStoryBlockModal from "./story/MainModuleStoryBlockModal";
import MainModuleStoryModal from "./story/MainModuleStoryModal";
import MainModuleStorySlideModal from "./story/MainModuleStorySlideModal";
import MainModuleProductModal from "./product/MainModuleProductModal";

const MainScreenModal = observer(() => {

    return(
        <>
            <Modal
                title="Редактирование блока"
                centered
                visible={_main.open}
                footer={null}
                onCancel={() => _main.close()}
                width={1000}
            >
                <form onSubmit={(e) => _main.post(e, _main.selected.type)}>
                    {_main.selected.type === "ksbuttontext" && _main.selectedType !== "banner" && <MainModuleButtonModal />}
                    {_main.selected.type === "ksslider" && _main.selectedType !== "banner" && <MainModuleSliderModal />}
                    {_main.selectedType === "banner" && <MainModuleBannerModal />}
                    {_main.selectedType === "video" && <MainModuleVideoModal />}
                    {_main.selected.type === "ksvideocategory" && <MainModuleVideoCategoryModal />}
                    {_main.selected.type === "ksstoryblock" && <MainModuleStoryBlockModal />}
                    {_main.selected.type === "ksstory" && <MainModuleStoryModal />}
                    {_main.selected.type === "ksstoryslide" && <MainModuleStorySlideModal />}
                    {_main.selected.type === "ksblockproduct" && <MainModuleProductModal />}

                    <br/>

                    <Button htmlType="submit" type="primary">Сохранить</Button>
                </form>
            </Modal>
        </>
    )
})

export default MainScreenModal