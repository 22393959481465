import { observer } from "mobx-react-lite"
import _main from "../../controllers/_main.screen.controller"
import { Table, Space, Tooltip, Button, Switch, Row, Col } from "antd"
import {ArrowDownOutlined, ArrowUpOutlined, PlusOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons"

const MainModuleStory = observer(({ item }) => {

    const columns = [
        {
            title: 'Изображение',
            dataIndex: 'photo',
            key: 'photo',
            render: text => <img src={text} alt="" height={`64px`} />,
        },
        {
            title: 'Текст кнопки',
            dataIndex: 'buttontext',
            key: 'buttontext',
        },
        {
            title: 'Назначение ссылки',
            dataIndex: 'typelink',
            key: 'typelink',
            render: (text, record) => (
                <>
                    {_main.typeLink(text, record.link).title}
                </>
            ),
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title="Активность">
                        <Switch size="small" checked={record.active > 0} onChange={e => _main.editActive(record.id, "ksstoryslide", e, () => _main.get())} />
                    </Tooltip>

                    <Button
                        size="small" shape="circle"
                        onClick={() => _main.selectStorySlide(record)}
                        icon={<EditOutlined />}
                    />

                    <Tooltip title="Переместить выше">
                        <Button
                            shape="circle" size="small" icon={<ArrowUpOutlined />}
                            onClick={() => _main.editPosition(record.id, "ksstoryslide", "moveUp", "storyid", () => _main.get())}
                        />
                    </Tooltip>
                    <Tooltip title="Переместить ниже">
                        <Button
                            shape="circle" size="small" icon={<ArrowDownOutlined />}
                            onClick={() => _main.editPosition(record.id, "ksstoryslide", "moveDown", "storyid", () => _main.get())}
                        />
                    </Tooltip>
                    <Tooltip title="Удалить элемент">
                        <Button
                            shape="circle" size="small" danger icon={<DeleteOutlined />}
                            onClick={() => _main.delete("ksstoryslide", record.id, () => _main.get())}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return(
        <div>
            <div>
                <Row justify="space-around" align="middle" gutter={[20, 20]}>
                    <Col span={12}>
                        <h5>{item.ksstoryblock.name}</h5>
                        <h4>{item.ksstoryblock.text}</h4>
                    </Col>
                    <Col span={12} align="right">
                        <Button
                            type="primary"
                            shape="round"
                            onClick={() => _main.selectStory({}, item.ksstoryblock.id)}
                        >
                            Добавить историю
                        </Button>
                    </Col>
                </Row>

                    {item.ksstoryblock.ksstory && item.ksstoryblock.ksstory.length > 0 && item.ksstoryblock.ksstory.map(record => (
                        <Row className="story" justify="space-around" align="middle">
                            <Col span={3}>
                                <img src={record.photo} alt="" height={`64px`} />
                            </Col>
                            <Col span={11}>
                                <h4>{record.name}</h4>
                            </Col>
                            <Col span={10} align="right">
                                <Space size="middle">
                                    <Tooltip title="Активность">
                                        <Switch size="small" checked={record.active > 0} onChange={e => _main.editActive(record.id, "ksstory", e, () => _main.get())} />
                                    </Tooltip>

                                    <Tooltip title="Добавить слайд">
                                        <Button
                                            size="small"
                                            shape="circle"
                                            onClick={() => _main.selectStorySlide({}, record.id)}
                                            icon={<PlusOutlined />}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Изменить">
                                        <Button
                                            size="small"
                                            shape="circle"
                                            onClick={() => _main.selectStory(record)}
                                            icon={<EditOutlined />}
                                        />
                                    </Tooltip>

                                    <Tooltip title="Переместить выше">
                                        <Button
                                            shape="circle" size="small" icon={<ArrowUpOutlined />}
                                            onClick={() => _main.editPosition(record.id, "ksstory", "moveUp", "storyblockid", () => _main.get())}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Переместить ниже">
                                        <Button
                                            shape="circle" size="small" icon={<ArrowDownOutlined />}
                                            onClick={() => _main.editPosition(record.id, "ksstory", "moveDown", "storyblockid", () => _main.get())}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Удалить баннер">
                                        <Button
                                            shape="circle" size="small" danger icon={<DeleteOutlined />}
                                            onClick={() => _main.delete("ksstory", record.id, () => _main.get())}
                                        />
                                    </Tooltip>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <h5>Слайды: {record.ksstoryslide.length}</h5>
                                {record.ksstoryslide && record.ksstoryslide.length > 0 &&
                                    <Table columns={columns} dataSource={record.ksstoryslide} pagination={false} />
                                }
                            </Col>
                        </Row>
                    ))}


            </div>
        </div>
    )
})

export default MainModuleStory