import { observer } from "mobx-react-lite";
import { Checkbox, Col, Input, InputNumber, Row, Select } from "antd";
import {useTranslation} from "react-i18next";
import shopProductController from "../controllers/productController"

const ShopProductMain = observer(({ productID=0 }) => {

    const { t } = useTranslation();

    return(
        <>
            <Row>
                <Col span={24} className="product-input_block">
                    <Checkbox
                        checked={shopProductController.product.view === 1 ? 'checked' : ''}
                        onChange={ (e) => shopProductController.setProduct("view", e.target.checked ? 1 : 0) }
                    >
                        {t('product:active')}
                    </Checkbox>
                </Col>
                <Col span={8} className="product-input_block left">
                    <label htmlFor="input1">{t('product:nameProduct')}</label>
                    <Input
                        name="name"
                        value={ shopProductController.product.name }
                        onChange={ (e) => shopProductController.setProduct("name", e.target.value) }
                        required
                    />
                </Col>
                <Col span={8} className="product-input_block left">
                    <label htmlFor="input1">{t('product:idCrm')}</label>
                    <Input
                        name="name"
                        value={ shopProductController.product.id_crm }
                        onChange={ (e) => shopProductController.setProduct("id_crm", e.target.value) }
                    />
                </Col>
                <Col span={8} className="product-input_block">
                    <label htmlFor="parentid">{t('product:parent')}</label>
                    <Select
                        value={shopProductController.product.parentid ? Number(shopProductController.product.parentid) : ""}
                        style={{ width: '100%' }}
                        onChange={ (e) => shopProductController.setProduct("parentid", e) }
                    >
                        {shopProductController.product.categoryslist.map(item => (
                            <Select.Option key={item.id} value={Number(item.id)}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={8} className="product-input_block left">
                    <label htmlFor="input3">{t('product:oldPrice')}</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={ shopProductController.product.priceold }
                        onChange={ (e) => shopProductController.setProduct("priceold", e) }
                    />
                </Col>
                <Col span={8} className="product-input_block left">
                    <label htmlFor="input2">{t('product:price')}</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={ shopProductController.product.price }
                        onChange={ (e) => shopProductController.setProduct("price", e) }
                    />
                </Col>
                <Col span={8} className="product-input_block left">
                    <label htmlFor="input3">Старая цена пользователя</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={ shopProductController.product.priceuserold }
                        onChange={ (e) => shopProductController.setProduct("priceuserold", e) }
                    />
                </Col>
                <Col span={8} className="product-input_block left">
                    <label htmlFor="input2">Окончательная цена пользователя</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={ shopProductController.product.priceuser }
                        onChange={ (e) => shopProductController.setProduct("priceuser", e) }
                    />
                </Col>
                <Col span={8} className="product-input_block">
                    <label htmlFor="count">{t('product:count')}</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={ shopProductController.product.count }
                        onChange={ (e) => shopProductController.setProduct("count", e) }
                    />
                </Col>
                <Col span={8} className="product-input_block left">
                    <label>{t('product:type')}</label>
                    <Select
                        placeholder={t('product:notSelected')}
                        onChange={ (e) => shopProductController.setProduct("type", e) }
                        style={{ width: '100%' }}
                        value={Number(shopProductController.product.type)}
                    >
                        <Select.Option key={"item0"} value={0}>{t('product:notSelected')}</Select.Option>
                        {shopProductController.product.statuses.map(item => (
                            <Select.Option key={item.id} value={Number(item.id)}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={24} className="product-input_block">
                    <label htmlFor="text">{t('product:description')}</label>
                    <Input.TextArea
                        value={shopProductController.product.text}
                        onChange={ (e) => shopProductController.setProduct("text", e.target.value) }
                        autoSize={{ minRows: 10, maxRows: 20 }}
                    />
                </Col>
            </Row>
        </>
    )
})

export default ShopProductMain