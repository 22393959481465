import { makeAutoObservable } from "mobx";
import sender from "../../../template/controllers/sender"
import hashController from "../../../template/controllers/hash.controller";
import sortingListItems from "../../../../helpers/sortingListItems";
import { notification } from "antd"
import instance from "../../../../store/axios";
import _elements from "./helpers/_elements.helper"

class _main {

    breadcrumb = [
        { key: "modules", link: "/modules", value: "Модули" },
        { key: "element", link: null, value: "Кнопки" }
    ]

    buttons = [
        { title: "Добавить блок", type: "button", ref: () => this.addBlock() }
    ]

    created = ""

    create = false
    list = []

    selected = {}
    selectedType = ""
    open = false
    parentID = 0

    statuses = []


    constructor() {
        makeAutoObservable(this)
    }

    set(name, type, value) {
        console.log(name)
        console.log(type)
        console.log(value)
        if(type !== "") {
            if(name === "typelink" && value !== this.selected[type][name]) {
                this.selected[type].link = ""
                this.selected[type].element = false
            }
            this.selected[type][name] = value
        } else {
            if(name === "typelink" && value !== this.selected[name]) {
                this.selected.link = ""
                this.selected.element = false
            }
            this.selected[name] = value
        }
    }

    setClass(className) {
        let returned = "ksPage"
        if(className === "ksstoryblock") { returned = "ksStoryblock" }
        if(className === "ksslider") { returned = "ksSlider" }
        if(className === "ksbaner") { returned = "ksBaner" }
        if(className === "ksblockproduct") { returned = "ksBlockproduct" }
        if(className === "ksbuttontext") { returned = "ksButtontext" }
        if(className === "ksvideocategory") { returned = "ksVideocategory" }
        if(className === "ksvideo") { returned = "ksVideo" }
        if(className === "ksstory") { returned = "ksStory" }
        if(className === "ksstoryslide") { returned = "ksStoryslide" }
        if(className === "ksstoryblock") { returned = "ksStoryblock" }
        if(className === "ksblockproduct") { returned = "ksBlockproduct" }

        return returned
    }

    getPath(className) {
        let returned = "page"

        if(className === "ksbuttontext") { returned = "buttontext" }
        if(className === "ksbaner") { returned = "poster" }
        if(className === "ksvideo") { returned = "video" }
        if(className === "ksvideocategory") { returned = "videocat" }
        if(className === "ksstory") { returned = "story" }
        if(className === "ksstoryslide") { returned = "storyslide" }
        if(className === "ksstoryblock") { returned = "storyblock" }
        if(className === "ksblockproduct") { returned = "blockproduct" }

        return returned
    }

    getType(type) {
        let returned = ""

        if(type === "ksslider") { returned = "Слайдер" }
        else if(type === "ksbuttontext") { returned = "Кнопка с текстом" }
        else if(type === "ksblockproduct") { returned = "Товары" }
        else if(type === "ksvideocategory") { returned = "Видео блок" }
        else if(type === "ksstoryblock") { returned = "Story" }
        else if(type === "infoblock") { returned = "Infoblock" }

        return returned
    }

    editActive(elementID, type, value, ref) {
        let facts = new FormData()
        facts.append("active", value ? 1 : 0)
        facts.append('hash', hashController.setHash(facts))

        sender.post(`/home/${this.getPath(type)}/${elementID}`, facts)
            .then(response => {
                ref()
            })
            .catch(response => {

            })
    }

    get() {
        console.log('!!!!!!!!!!!!!!!!!!!!!!!')
        sender.get(`/home/page`)
            .then(response => {
                this.list = response.data
                console.log(response)
            })
            .catch(response => {
                console.log(response)
            })
    }

    editColor() {

    }

    editPosition(elementID, type, action, key, ref) {
        sortingListItems(this.setClass(type), action, elementID, key, ref)
    }

    addBlock() {
        this.create = true
    }

    delete(type, elementID, ref) {
        if(window.confirm("Вы уверены, что хотите удалить элемент?")) {
            let className = this.setClass(type)
            let facts = {
                typestructure: className
            }

            sender.delete(`/product/delete/${elementID}`, {data: "typestructure=" + className + "&hash=" + hashController.setHashDelete(facts)})
                .then(success => {
                    ref()
                })
                .catch(error => console.log(error.response))
        }
    }

    selectPage(item) {
        this.selected = item
        this.open = true
    }

    close() {
        this.selected = {}
        this.selectedType = ""
        this.open = false
        this.parentID = 0
        this.created = ""
        this.get()
    }

    typeLink(type, link) {
        let title = ""
        let element = link

        if(type === "news") {
            title = "Ссылка на новость"
            element = `Новость `
        } else if(type === "category") {
            title = "Ссылка на категорию товаров"
        } else if(type === "product") {
            title = "Ссылка на товар"
        } else if(type === "url") {
            title = "Ссылка на сайт"
        } else if(type === "url_blank") {
            title = "Ссылка на сайт в отдельной вкладке"
        }

        return {
            title: title,
            element: element
        }
    }

    post(e, type) {
        e.preventDefault()

        console.log(this.created)

        if(this.created !== "" && this.created !== "none") {
            let facts = new FormData()
            facts.append("place", "home")
            facts.append("typeview", "1")
            facts.append("active", "1")
            facts.append('hash', hashController.setHash(facts))

            let typeCreate = ""
            if(type === "banner") { typeCreate = "slider" }
            else if(type === "video") { typeCreate = "videocat" }
            else if(type === "ksstoryblock") { typeCreate = "storyblock" }
            else if(type === "ksstory") { typeCreate = "storyblock" }

            console.log(`Create | ${type} | ${typeCreate}`)

            sender.post(`/home/${typeCreate}/0`, facts)
                .then(response => {
                    if(response.data.id) {

                        this.parentID = response.data.id
                        this.postElement(type)
                    } else {
                        console.log("HZ")
                        console.log(response)
                    }
                })
                .catch(response => {
                    console.log("Error create post")
                })



        } else {
            this.postElement(type)
        }
        // this.closeCreate()
    }

    postElement(type) {
        if(type === "ksbuttontext") { this.postButtonText() }
        else if(type === "banner") { this.postBanner() }
        else if(type === "ksslider") { this.postSlider() }
        else if(type === "video") { this.postVideo() }
        else if(type === "ksvideocategory") { this.postVideoCategory() }
        else if(type === "ksstoryblock") { this.postStoryBlock() }
        else if(type === "ksstoryslide") { this.postStorySlide() }
        else if(type === "ksstory") { this.postStory() }
        else if(type === "ksblockproduct") { this.postBlockProduct() }
    }

    postBlockProduct() {
        let type = "ksblockproduct"

        let facts = new FormData()
        facts.append("name", this.selected[type].name ? this.selected[type].name : "")
        facts.append("active", this.selected[type].active ? this.selected[type].active : "0")
        facts.append("typeview", "1")
        facts.append("type", this.selected[type].type ? this.selected[type].type : "")

        if(this.selected[type].type === "product") {
            if(!Array.isArray(this.selected[type].blockid) || this.selected[type].blockid.length === 0) {
                notification.error({
                    message: "Недостаточно данных",
                    description: "Не выбраны товары"
                })
                return false
            }
        } else if(this.selected[type].type === "productcategory") {
            if(isNaN(+this.selected[type].blockid) || +this.selected[type].blockid === 0) {
                notification.error({
                    message: "Недостаточно данных",
                    description: "Не выбрана категория товаров"
                })
                return false
            }
        } else if(this.selected[type].type === "status") {
            if(isNaN(+this.selected[type].blockid) || +this.selected[type].blockid === 0) {
                notification.error({
                    message: "Недостаточно данных",
                    description: "Не выбран статус товаров"
                })
                return false
            }
        }

        if(this.selected[type].type === "product") {

            if(Array.isArray(this.selected[type].blockid)) {
                this.selected[type].blockid.map(item => {
                    facts.append("blockid[]", item)
                })
            }
        } else {
            facts.append("blockid", this.selected[type].blockid)
        }

        facts.append('hash', hashController.setHash(facts))

        let url = this.selected[type].id ? `/home/blockproduct/${this.selected[type].id}` : `/home/blockproduct/0`
        sender.post(url, facts)
            .then(response => {
                if(this.created !== "") {
                    this.createPage("ksblockproduct", response.data.id)
                } else {
                    notification.success({
                        message: 'Готово',
                        description: 'Элемент успешно изменен'
                    })

                    this.get()
                    this.close()
                }
            })
            .catch(response => {
                console.log(response)
            })
    }

    postStory() {

        let facts = new FormData()
        facts.append("name", this.selected.name ? this.selected.name : "")
        facts.append("active", this.selected.active ? this.selected.active : 0)
        facts.append("text", this.selected.text ? this.selected.text : "")
        facts.append("typeview", "1")
        facts.append("photo", this.selected.photo ? this.selected.photo : "")
        facts.append("storyblockid", this.selected.storyblockid ? this.selected.storyblockid : this.parentID)

        facts.append('hash', hashController.setHash(facts))

        let url = this.selected.id ? `/home/story/${this.selected.id}` : `/home/story/0`
        sender.post(url, facts)
            .then(response => {
                if(this.created !== "") {
                    this.createPage("ksstoryblock", this.parentID)
                } else {
                    notification.success({
                        message: 'Готово',
                        description: 'Элемент успешно изменен'
                    })

                    this.get()
                    this.close()
                }
            })
            .catch(response => {
                console.log(response)
            })
    }

    postStorySlide() {

        if(!this.checkLink(this.selected.typelink, this.selected.link)) {
            notification.error({
                message: "Недостаточно данных",
                description: "Не указано назначение ссылки"
            })
            return false
        }

        let facts = new FormData()
        facts.append("name", this.selected.name ? this.selected.name : "")
        facts.append("active", this.selected.active ? this.selected.active : 0)
        facts.append("text", this.selected.text ? this.selected.text : "")
        facts.append("photo", this.selected.photo ? this.selected.photo : "")

        facts.append("namecolor", this.selected.namecolor ? this.selected.namecolor : "")
        facts.append("textcolor", this.selected.textcolor ? this.selected.textcolor : "")
        facts.append("storyid", this.selected.storyid ? this.selected.storyid : this.parentID)
        facts.append("typelink", this.selected.typelink ? this.selected.typelink : "")
        facts.append("link", this.selected.link ? this.selected.link : "")
        facts.append("button", this.selected.button ? this.selected.button : 0)
        facts.append("buttoncolor", this.selected.buttoncolor ? this.selected.buttoncolor : "")
        facts.append("buttontext", this.selected.buttontext ? this.selected.buttontext : "")
        facts.append("buttontextcolor", this.selected.buttontextcolor ? this.selected.buttontextcolor : "")
        facts.append("imagedisplaystory", this.selected.imagedisplaystory ? this.selected.imagedisplaystory : 1)

        facts.append('hash', hashController.setHash(facts))

        let url = this.selected.id ? `/home/storyslide/${this.selected.id}` : `/home/storyslide/0`
        sender.post(url, facts)
            .then(response => {
                notification.success({
                    message: 'Готово',
                    description: 'Элемент успешно изменен'
                })
                this.get()
                this.close()
            })
            .catch(response => {
                console.log(response)
            })
    }

    postStoryBlock() {
        let type = "ksstoryblock"

        let facts = new FormData()
        facts.append("name", this.selected[type].name ? this.selected[type].name : "")
        facts.append("active", this.selected[type].active ? this.selected[type].active : 0)
        facts.append("typeview", "1")

        facts.append('hash', hashController.setHash(facts))

        let url = this.selected[type].id ? `/home/storyblock/${this.selected[type].id}` : `/home/storyblock/0`
        sender.post(url, facts)
            .then(response => {
                if(this.selected[type].id && this.selected[type].id > 0) {

                    notification.success({
                        message: 'Готово',
                        description: 'Элемент успешно изменен'
                    })

                    this.get()
                    this.close()
                } else {

                }
            })
            .catch(response => {
                console.log(response)
            })
    }

    postButtonText() {
        let type = "ksbuttontext"

        if(!this.checkLink(this.selected[type].typelink, this.selected[type].link)) {
            notification.error({
                message: "Недостаточно данных",
                description: "Не указано назначение ссылки"
            })
            return false
        }

        let facts = new FormData()
        facts.append("name", this.selected[type].name ? this.selected[type].name : "")
        facts.append("active", this.selected[type].active ? this.selected[type].active : 0)
        facts.append("text", this.selected[type].text ? this.selected[type].text : "")
        facts.append("buttontext", this.selected[type].buttontext ? this.selected[type].buttontext : "")
        facts.append("buttontextcolor", this.selected[type].buttontextcolor ? this.selected[type].buttontextcolor : "")
        facts.append("buttoncolor", this.selected[type].buttoncolor ? this.selected[type].buttoncolor : "")
        facts.append("typelink", this.selected[type].typelink ? this.selected[type].typelink : "")
        facts.append("link", this.selected[type].link ? this.selected[type].link : "")

        facts.append('hash', hashController.setHash(facts))

        let url = this.selected[type].id ? `/home/buttontext/${this.selected[type].id}` : `/home/buttontext/0`
        sender.post(url, facts)
            .then(response => {
                if(this.created !== "") {
                    this.createPage("ksbuttontext", response.data.id)
                } else {
                    notification.success({
                        message: 'Готово',
                        description: 'Элемент успешно изменен'
                    })
                    this.get()
                    this.close()
                }
            })
            .catch(response => {
                console.log(response)
            })
    }

    createPage(type, parentID) {
        let facts = new FormData()
        facts.append("active", "1")
        facts.append("place", "home")
        facts.append("type", type)
        facts.append("blockid", parentID)

        facts.append('hash', hashController.setHash(facts))

        sender.post(`/home/page/0`, facts)
            .then(response => {
                console.log(`Создан page = ${response.data.id}`)
                notification.success({
                    message: 'Готово',
                    description: 'Элемент успешно изменен'
                })

                this.get()
                this.close()
            })
            .catch(response => {
                console.log(response)
            })
    }

    postBanner() {

        if(!this.checkLink(this.selected.typelink, this.selected.link)) {
            notification.error({
                message: "Недостаточно данных",
                description: "Не указано назначение ссылки"
            })
            return false
        }

        let facts = new FormData()
        facts.append("name", this.selected.name ? this.selected.name : "")
        facts.append("active", this.selected.active ? this.selected.active : 0)
        facts.append("sliderid", this.selected.sliderid ? this.selected.sliderid : this.parentID)
        facts.append("place", "home")
        facts.append("typelink", this.selected.typelink ? this.selected.typelink : "")
        facts.append("link", this.selected.link ? this.selected.link : "")
        facts.append("photo", this.selected.photo ? this.selected.photo : "")
        facts.append('hash', hashController.setHash(facts))

        let url = this.selected.id ? `/home/poster/${this.selected.id}` : `/home/poster/0`

        console.log(url)
        console.log(`parent = ${this.parentID}`)

        sender.post(url, facts)
            .then(response => {
                console.log(response)
                if(this.created !== "") {
                    this.createPage("ksslider", this.parentID)
                } else {
                    notification.success({
                        message: 'Готово',
                        description: 'Элемент успешно изменен'
                    })

                    this.get()
                    this.close()
                }

            })
            .catch(response => {
                console.log(response)
            })
    }

    postSlider() {
        let type = "ksslider"

        let facts = new FormData()
        facts.append("name", this.selected[type].name ? this.selected[type].name : "")
        facts.append("active", this.selected[type].active ? this.selected[type].active : 0)
        facts.append("text", this.selected[type].text ? this.selected[type].text : "")
        facts.append("place", "home")
        facts.append("typeview", "1")

        facts.append('hash', hashController.setHash(facts))

        let url = this.selected[type].id ? `/home/slider/${this.selected[type].id}` : `/home/slider/0`
        sender.post(url, facts)
            .then(response => {
                notification.success({
                    message: 'Готово',
                    description: 'Элемент успешно изменен'
                })
                this.get()
                this.close()
            })
            .catch(response => {
                console.log(response)
            })
    }

    postVideoCategory() {
        let type = "ksvideocategory"

        let facts = new FormData()
        facts.append("name", this.selected[type].name ? this.selected[type].name : "")
        facts.append("active", this.selected[type].active ? this.selected[type].active : 0)
        facts.append("text", this.selected[type].text ? this.selected[type].text : "")
        facts.append("parentid", 0)

        facts.append('hash', hashController.setHash(facts))

        sender.post(this.selected[type].id ? `/home/videocat/${this.selected[type].id}` : `/home/videocat/0`, facts)
            .then(response => {
                notification.success({
                    message: 'Готово',
                    description: 'Элемент успешно изменен'
                })
                this.get()
                this.close()
            })
            .catch(response => {
                console.log(response)
            })
    }

    postVideo() {
        let facts = new FormData()
        facts.append("name", this.selected.name ? this.selected.name : "")
        facts.append("active", this.selected.active ? this.selected.active : 0)
        facts.append("text", this.selected.text ? this.selected.text : "")
        facts.append("parentid", this.selected.parentid ? this.selected.parentid : this.parentID)
        facts.append("photo", this.selected.photo ? this.selected.photo : "")
        facts.append("video", this.selected.video ? this.selected.video : "")

        facts.append('hash', hashController.setHash(facts))

        let url = this.selected.id ? `/home/video/${this.selected.id}` : `/home/video/0`

        sender.post(url, facts)
            .then(response => {
                if(this.created !== "") {
                    this.createPage("ksvideocategory", this.parentID)
                } else {
                    notification.success({
                        message: 'Готово',
                        description: 'Элемент успешно изменен'
                    })

                    this.get()
                    this.close()
                }

            })
            .catch(response => {
                console.log(response)
            })
    }

    selectBanner(banner={}, parentID=0) {
        banner = { ...banner, active: 1 }
        this.selected = banner
        this.selected.type = "banner"
        this.open = true
        this.selectedType = "banner"
        this.parentID = parentID
    }

    selectVideo(video={}, parentID=0) {
        video = { ...video, active: 1 }
        this.selected = video
        this.selected.type = "video"
        this.open = true
        this.selectedType = "video"
        this.parentID = parentID
    }

    selectStory(story={}, parentID=0) {
        story = { ...story, active: 1 }
        this.selected = story
        this.selected.type = "ksstory"
        this.open = true
        this.selectedType = "ksstory"
        this.parentID = parentID
    }

    selectStorySlide(story={}, parentID=0) {
        story = { ...story, active: 1 }
        this.selected = story
        this.selected.type = "ksstoryslide"
        this.open = true
        this.selectedType = "ksstoryslide"
        this.parentID = parentID
    }

    selectBlockProduct(block={}, parentID=0) {
        block = { ...block, active: 1 }
        this.selected = block
        this.selected.type = "ksblockproduct"
        this.open = true
        this.selectedType = "ksblockproduct"
        this.parentID = parentID
    }

    closeCreate() {
        this.create = false
        this.created = ""
    }

    createBlock(type) {
        if(type === "ksslider") {
            this.created = "slider"
            this.selectBanner({ active: 1 })
        } else if(type === "ksbuttontext") {
            this.created = "none"
            this.selectPage({ ksbuttontext: { active: 1, typelink: "", link: "" }, type: "ksbuttontext" })
        } else if(type === "ksvideocategory") {
            this.created = "videocat"
            this.selectVideo({ active: 1 })
        } else if(type === "ksstoryslide") {
            this.created = "none"
            this.selectPage({ ksbuttontext: { active: 1 }, type: "ksbuttontext" })
        } else if(type === "ksstory") {
            this.created = "ksstoryblock"
            this.selectStory({ active: 1 })
        } else if(type === "ksblockproduct") {
            this.created = "none"
            this.selectBlockProduct({ ksblockproduct: { active: 1 }, type: "ksblockproduct" })
        } else if(type === "infoblock") {
            this.created = "none"
            this.createPage("infoblock", 0)
        }
        this.create = false
    }

    deleteProductFromArray(productID, el) {
        this.selected[el].blockid = this.selected[el].blockid.filter(el => Number(el) !== productID)
        this.selected[el].product = this.selected[el].product.filter(el => Number(el.id) !== productID)
    }

    addProductToArray(element, el) {
        let arr = this.selected[el].product
        arr.push(element)
        this.selected[el].blockid.push(element.id)
        this.selected[el].product = arr
    }

    changeProduct(type, el) {
        console.log(type)
        if(this.selected[el].type !== type) {
            this.selected[el].element = {}
            this.selected[el].type = type
            this.selected[el].blockid = type === "product" ? [] : null

            if(type === "product") {
                this.selected[el].product = []
            }
            if(type === "productcategory") {
                _elements.setType("category")
            }
        }
    }

    getStatuses() {
        instance.get("/shop/statuses")
            .then(response => {
                this.statuses = response.data.data[0]
            })
    }

    checkLink(type, value) {
        if(type !== "") {
            if(value === "" || value === 0 || value === null) {
                return false
            }
        }
        return true
    }

}

export default new _main()