import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {Col, Descriptions, Row, Typography, Button, Breadcrumb} from 'antd';
import {withTranslation, useTranslation} from "react-i18next";
import instance from "../../store/axios";
import {useParams} from "react-router-dom";

const { Title } = Typography;

function UserDetail() {
    let { userID } = useParams();
    const { t } = useTranslation();
    const [user, setUser] = useState({
        id: 0,
        firstname: '',
        lastname: '',
        surname: '',
        email: '',
        phone: '',
        birthday: 0
    });

    useEffect(() => {
        instance.get('/users/user/' + userID + '/').then(response => {
            console.log(response);

            let date = 0;
            if(Number(response.data.data.birthday) > 0) {
                date = new Date(Number(response.data.data.birthday) * 1000);

                date = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
            }

            setUser({
                id: response.data.data.id,
                firstname: response.data.data.firstname,
                lastname: response.data.data.lastname,
                surname: response.data.data.surname,
                email: response.data.data.email,
                phone: response.data.data.phone,
                birthday: date,
            })
        })
    }, []);

    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Title>{t('user:info')}</Title>
                </Col>
                <Col span={8} className="right">
                    <Button type="dashed" href={"/users"}>{t('user:list')}</Button>
                </Col>
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('user:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/users"}>{t('user:users')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('user:user')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Descriptions
                size="small"
            >
                <Descriptions.Item label="ID">{user.id}</Descriptions.Item>
                <Descriptions.Item label="E-mail">{user.email}</Descriptions.Item>
                <Descriptions.Item label={t('user:phone')}>{user.phone}</Descriptions.Item>
                <Descriptions.Item label={t('user:name')}>{user.firstname}</Descriptions.Item>
                <Descriptions.Item label={t('user:surname')}>{user.lastname}</Descriptions.Item>
                <Descriptions.Item label={t('user:middle')}>{user.surname}</Descriptions.Item>
                {Number(user.birthday) !== 0 &&
                    <Descriptions.Item label={t('user:birthday')}>{user.birthday}</Descriptions.Item>
                }
            </Descriptions>
        </>
    )
}

export default withTranslation()(UserDetail);