import React, {useState, useEffect} from "react";
import axios from "axios";
import {notification, Modal, Button, Col, Row, Typography, Input, Table, Select, Space, Tooltip, Breadcrumb} from 'antd';
import {DeleteOutlined, SettingOutlined} from "@ant-design/icons";

import { withTranslation } from 'react-i18next';
import instance from "../../store/axios";

const { Title } = Typography;
const { Option } = Select;

function Contacts({ t }) {
    const [contacts, setContacts] = useState({
        list: [],
        displayBasic: false,
        type: null,
        title: null,
        value: null,
        id: null
    });

    useEffect(() => {
        instance.get('/info/contacts/').then(response => {
            if(response.data.code === 200){
                setContacts({ ...contacts, list: response.data.data })
            }
        })
    }, [contacts.type]);

    function renderFooter() {
        return (
            <div>
                <Button label={t('contacts:undo')} icon="pi pi-times" onClick={() => setContacts({ ...contacts, displayBasic: false })} className="p-button-text" />
                <Button label={t('contacts:add')} icon="pi pi-check" autoFocus disabled={contacts.title ? '' : 'disabled'} onClick={() => addContact()} />
            </div>
        );
    }

    function addContact() {
        let facts = new FormData();
        facts.append('id', contacts.id);
        facts.append('title', contacts.title);
        facts.append('type', contacts.type);
        facts.append('value', contacts.value);

        instance.post('/info/contacts/', facts).then(response => {
            if(response.data.code === 201 || response.data.code === 200){
                notification.success({
                    placement: "bottomLeft",
                    message: t('contacts:success'),
                    description: t('contacts:editSuccess'),
                });
                setContacts({
                    ...contacts,
                    displayBasic: false,
                    id: null,
                    title: null,
                    value: null,
                    type: null
                })
            }
        })
    }

    function openItem(id) {
        instance.get('/info/contacts/' + id + '/').then(response => {
            if(response.data.code === 200){
                console.log(response.data);
                setContacts({
                    ...contacts,
                    id: response.data.data.id,
                    title: response.data.data.title,
                    type: response.data.data.type,
                    value: response.data.data.value,
                    displayBasic: true
                })
            }
        })
    }

    function deleteContact(id) {
        if (window.confirm(t('contacts:confirm'))) {
            instance.delete('/info/contacts/' + id + '/').then(response => {
                if (response.data.code === 200) {
                    notification.success({
                        placement: "bottomLeft",
                        message: t('contacts:success'),
                        description: t('contacts:deleteSuccess'),
                    });
                    instance.get('/info/contacts/').then(response => {
                        if (response.data.code === 200) {
                            setContacts({...contacts, list: response.data.data})
                        }
                    })
                }
            })
        }
    }

    const openDialog = () => {
        setContacts({ ...contacts, displayBasic: true });
    };

    const columns = [
        {
            title: t('contacts:type'),
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: t('contacts:name'),
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: t('contacts:value'),
            dataIndex: 'value',
            key: 'value'
        },
        {
            title: t('contacts:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space>
                    <Tooltip title={t('contacts:edit')}>
                        <Button type="dashed" shape="circle" onClick={() => openItem(record.id)} icon={<SettingOutlined />} />
                    </Tooltip>
                    <Tooltip title={t('contacts:delete')}>
                        <Button type="dashed" shape="circle" icon={<DeleteOutlined />} danger onClick={() => deleteContact(record.id)} />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handleChange = (value) => {
        setContacts({ ...contacts, type: value });
    };

    const closeModal = () => {
        setContacts({
            ...contacts,
            displayBasic: false,
            type: null,
            title: null,
            value: null,
            id: null
        });
    };

    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Title>{t('contacts:contacts')}</Title>
                </Col>
                <Col span={8} align="right">
                    <Button type="primary" onClick={openDialog}>{t('contacts:create')}</Button>
                </Col>
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('contacts:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('contacts:contacts')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Table columns={columns} dataSource={contacts.list} pagination={false} />

            <Modal
                visible={contacts.displayBasic}
                title={t('contacts:editContact')}
                onCancel={closeModal}
                footer={null}
                centered={true}
            >
                <form>
                    <div className="col-lg-12 p-work-form_block">
                        <label htmlFor="type" className="all-label">{t('contacts:type')}</label>
                        <Select placeholder={t('contacts:notSelected')} value={contacts.type} onChange={handleChange} style={{ width: '100%' }} required>
                            <Option value="phone">{t('contacts:phone')}</Option>
                            <Option value="email">Email</Option>
                            <Option value="address">{t('contacts:address')}</Option>
                            <Option value="schedule">schedule</Option>
                            <Option value="vk">{t('contacts:vk')}</Option>
                            <Option value="instagram">Instagram</Option>
                            <Option value="facebook">Facebook</Option>
                            <Option value="twitter">Twitter</Option>
                            <Option value="telegram">Telegram</Option>
                            <Option value="website">{t('contacts:site')}</Option>
                            <Option value="whatsapp">WhatsApp</Option>
                        </Select>
                    </div>
                    <div className="col-lg-12 p-work-form_block">
                        <div className="form-group">
                            <label htmlFor="input1" className="all-label">{t('contacts:nameName')}</label>
                            <Input
                                disabled={contacts.type ? '' : 'disabled'}
                                value={contacts.title}
                                onChange={(e) => setContacts({ ...contacts, title: e.target.value })}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 p-work-form_block">
                        <div className="form-group">
                            <label htmlFor="input1" className="all-label">{t('contacts:value')}</label>
                            <Input
                                value={contacts.value}
                                disabled={contacts.title ? '' : 'disabled'}
                                onChange={(e) => setContacts({ ...contacts, value: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 work-button_block">
                        <Space>
                            <Button type="primary" disabled={contacts.title ? '' : 'disabled'} onClick={() => addContact()}>{t('contacts:save')}</Button>
                            <Button onClick={() => setContacts({ ...contacts, displayBasic: false })}>{t('contacts:undo')}</Button>
                        </Space>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default withTranslation()(Contacts);