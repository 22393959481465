import { observer } from "mobx-react-lite"
import _main from "./controllers/_main.screen.controller"
import TemplateTitle from "../../template/title/TemplateTitle"
import { useEffect } from "react"
import MainModuleButton from "./modules/button/MainModuleButton";
import BlockTitle from "./modules/BlockTitle";
import MainScreenModal from "./modules/MainScreenModal";
import MainModuleSlider from "./modules/slider/MainModuleSlider";
import { Modal, Button } from "antd"
import MainModuleVideo from "./modules/video/MainModuleVideo";
import MainModuleProduct from "./modules/product/MainModuleProduct";
import MainModuleStory from "./modules/story/MainModuleStory";

const InfoBlock = () => {
    return(
        <>

        </>
    )
}

const ViewType = observer(({ item }) => {

    return(
        <div className="in">
            {item.type === "ksstoryblock" && <MainModuleStory item={ item } />}
            {item.type === "ksslider" && <MainModuleSlider item={ item } />}
            {item.type === "ksblockproduct" && <MainModuleProduct item={ item } />}
            {item.type === "ksbuttontext" && <MainModuleButton item={ item } />}
            {item.type === "ksvideocategory" && <MainModuleVideo item={ item } />}
            {item.type === "infoblock" && <InfoBlock />}
        </div>
    )
})

const MainScreen = observer(() => {

    useEffect(() => {
        _main.get()
    }, [])


    return(
        <>
            <TemplateTitle
                title="Главный экран"
                breadcrumb={_main.breadcrumb}
                buttons={_main.buttons}
            />

            <div className="main-screen">
                {_main.list.length > 0 && _main.list.map(item =>
                    <>
                        <div className="item">
                            <BlockTitle
                                title={`#${item.id} ${_main.getType(item.type)}`}
                                item={item}
                            />
                            <ViewType item={item} />
                        </div>
                    </>
                )}
            </div>

            <MainScreenModal />

            <Modal
                title="Выберите тип создаваемого блока"
                centered
                visible={_main.create}
                footer={null}
                onCancel={() => _main.closeCreate()}
            >
                <Button block onClick={() => _main.createBlock("ksslider")}>Слайдер</Button><br/><br/>
                <Button block onClick={() => _main.createBlock("ksbuttontext")}>Текст с кнопкой</Button><br/><br/>
                <Button block onClick={() => _main.createBlock("ksblockproduct")}>Блок товаров</Button><br/><br/>
                <Button block onClick={() => _main.createBlock("ksvideocategory")}>Блок видео</Button><br/><br/>
                <Button block onClick={() => _main.createBlock("ksstory")}>Story</Button><br/><br/>
                <Button block onClick={() => _main.createBlock("infoblock")}>Инфоблок</Button>
            </Modal>
        </>
    )
})

export default MainScreen