import { observer } from "mobx-react-lite"
import _main from "../../controllers/_main.screen.controller"
import { Table, Space, Tooltip, Button, Switch } from "antd"
import {ArrowDownOutlined, ArrowUpOutlined, CopyOutlined, DeleteOutlined} from "@ant-design/icons"

const MainModuleProduct = observer(({ item }) => {

    const columnsProducts = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Изображение',
            dataIndex: 'photo',
            key: 'photo',
            render: text => {
                if(text && text[0] && text[0].path && text[0].path !== "") {
                    return <img src={text[0].path} alt="" width={`80px`} />
                } else {
                    return ""
                }
            }
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price'
        }
    ]



    return(
        <div>
            <div>
                {/*<p>{item.ksblockproduct.name}</p>*/}
                {/*<p>{item.ksblockproduct.text}</p>*/}

                {item.ksblockproduct.type === "productcategory" &&
                    <>
                        <h5>Категория товаров: {item.ksblockproduct.element.name}</h5>
                    </>
                }
                {item.ksblockproduct.type === "status" &&
                    <>
                        <h5>Товары по статусу: <span style={{ color: `#${item.ksblockproduct.element.colorhex}` }}>{item.ksblockproduct.element.name}</span></h5>
                    </>
                }
                {item.ksblockproduct.type === "product" &&
                    <>
                        <h5>Список товаров</h5>
                        <Table columns={columnsProducts} dataSource={item.ksblockproduct.product} pagination={false} />
                    </>
                }

                {/*<Button type="primary" onClick={() => _main.selectVideo({}, item.ksvideocategory.id)}>Добавить видео</Button>*/}

                {/*<Table columns={columns} dataSource={item.ksvideocategory.ksvideo} />*/}


            </div>
        </div>
    )
})

export default MainModuleProduct