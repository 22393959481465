import { observer } from "mobx-react-lite"
import _elements from "../controllers/helpers/_elements.helper";
import _main from "../controllers/_main.screen.controller"
import { Button, Table } from "antd"
import { useEffect } from "react"

const MainModuleCategories = observer(({ select }) => {

    useEffect(() => {
        _elements.get()
    }, [])

    const columnsCatalog = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Действия',
            key: 'actions',
            render: (record) => (
                <>
                    {record.subcategory
                        ?   <Button onClick={() => _elements.get(record.id)}>Перейти</Button>
                        :   Number(select) === Number(record.id)
                            ? <Button type="primary">Выбрано</Button>
                            : <Button onClick={() => _main.set("blockid", "ksblockproduct", record.id)}>Выбрать</Button>
                    }

                </>
            )
        },
    ]

    return(
        <Table
            columns={columnsCatalog}
            dataSource={_elements.list}
        />
    )
})

export default MainModuleCategories