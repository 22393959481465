import {observer} from "mobx-react-lite"
import {Button, Col, Row, Space, Switch, Tooltip} from "antd"
import {ArrowDownOutlined, ArrowUpOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons"
import _main from "../controllers/_main.screen.controller"

const BlockTitle = observer(({ title, item }) => {

    return(
        <Row justify="space-around" align="middle" className="title">
            <Col span={12}>
                <span className="title-span">{title}</span>
            </Col>
            <Col span={12} align="right">
                <Space>
                    <Tooltip title="Активность">
                        <Switch
                            size="small"
                            checked={item.active > 0}
                            onChange={e => _main.editActive(item.id, "page", e, () => _main.get())}
                        />
                    </Tooltip>

                    <Tooltip title="Изменить">
                        <Button
                            size="small" shape="circle"
                            onClick={() => _main.selectPage(item)}
                            icon={<EditOutlined />}
                        />
                    </Tooltip>

                    <Tooltip title="Переместить выше">
                        <Button
                            shape="circle" size="small" icon={<ArrowUpOutlined />}
                            onClick={() => _main.editPosition(item.id, "page", "moveDown", "place", () => _main.get())}
                        />
                    </Tooltip>
                    <Tooltip title="Переместить ниже">
                        <Button
                            shape="circle" size="small" icon={<ArrowDownOutlined />}
                            onClick={() => _main.editPosition(item.id, "page", "moveUp", "place", () => _main.get())}
                        />
                    </Tooltip>

                    <Tooltip title="Удалить блок">
                        <Button
                            shape="circle" size="small" danger icon={<DeleteOutlined />}
                            onClick={() => _main.delete("page", item.id, () => _main.get())}
                        />
                    </Tooltip>
                </Space>
            </Col>
        </Row>
    )
})

export default BlockTitle