import { observer } from "mobx-react-lite"
import { Row, Col, Checkbox, Input } from "antd"
import _main from "../../controllers/_main.screen.controller"

const MainModuleSliderModal = observer(() => {

    let el = "ksslider"

    return(
        <Row gutter={16}>
            <Col span={24}>
                <Checkbox
                    checked={Number(_main.selected[el].active) > 0}
                    onChange={(e) => _main.set("active", el, e.target.checked ? 1 : 0)}
                >
                    Активность
                </Checkbox>
            </Col>
            <Col span={24} className="gutter-row">
                <label htmlFor="name" className="all-label">Наименование</label>
                <Input
                    value={_main.selected[el].name}
                    id="name"
                    onChange={(e) => _main.set("name", el, e.target.value)}
                />
            </Col>
        </Row>
    )
})

export default MainModuleSliderModal