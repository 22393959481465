import { observer } from "mobx-react-lite";
import shopProductController from "./controllers/productController"
import ShopProductMain from "./modules/ShopProductMain";
import React, { useEffect } from "react";
import { Tabs, Button } from "antd"
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TemplateTitle from "../../../../template/title/TemplateTitle";
import ShopProductImages from "./modules/ShopProductImages";
import ShopProductSimilar from "./modules/ShopProductSimilar";
import ShopProductWith from "./modules/ShopProductWith";
import ShopProductFilter from "./modules/ShopProductFilter";

const ShopProduct = observer(() => {

    const { productID, createID } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        shopProductController.getProduct(productID > 0 ? productID : createID, productID > 0 ? "product" : "create")
    }, [])

    return(
        <form onSubmit={(event) => shopProductController.sendForm(event)}>
            {Object.keys(shopProductController.product).length > 0 &&
                <>
                    <TemplateTitle
                        title="Редактирование товара"
                        breadcrumb={shopProductController.breadcrumb}
                        buttons={[
                            { type: "submit", title: "Сохранить", primary: true },
                            {
                                type: "link",
                                title: shopProductController.product.isvariant > 0 ? "Вернуться к главному товару" : "Вернуться к списку",
                                link: shopProductController.product.isvariant > 0 ? "/shop/catalog/product/" + shopProductController.product.productid : "/shop/catalog/category/" + shopProductController.product.parentid
                            }
                        ]}
                    />

                    {shopProductController.product.userid > 0 &&
                        <>
                            <Button type="dashed" href={`/users/${shopProductController.product.userid}`} target="_blank">Перейти к создателю товара</Button><br/><br/>
                        </>
                    }

                    <Tabs defaultActiveKey="1" onChange={ (tab) => shopProductController.setTab(tab) }>
                        <Tabs.TabPane tab="Основное" key="main">
                            <ShopProductMain productID={ productID } />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Изображение" key="image">
                            <ShopProductImages />
                        </Tabs.TabPane>
                        {shopProductController.product.id && shopProductController.product.id > 0 &&
                            <>
                                <Tabs.TabPane tab="Характеристики" key="properties">
                                    <ShopProductFilter />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Похожие товары" key="similar">
                                    <ShopProductSimilar />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Рекомендуемые товары" key="with">
                                    <ShopProductWith />
                                </Tabs.TabPane>
                            </>
                        }
                    </Tabs>
                </>
            }
        </form>
    )
})

export default ShopProduct