import { makeAutoObservable } from "mobx";
import sender from "../../../../../template/controllers/sender";
import instance from "../../../../../../store/axios";
import pc from "../../../../properties/controllers/properties.controller";
import hashController from "../../../../../template/controllers/hash.controller";
import { message, notification } from 'antd';
import deleteFile from "../../../../../../store/deleteFile.controller";

class shopProductController {
    constructor() {
        makeAutoObservable(this)
    }

    breadcrumb = [
        { link: "/shop", value: "Мой магазин" },
        { link: "/shop/catalog", value: "Каталог" }
    ]
    product = {}
    products = []
    tab = "main"

    limit = 10;
    page = 1;
    search = {
        name: "",
        id: null,
        id_crm: null
    };

    pagination = {
        page: 1,
        all: 0
    };

    fromSearch = 0;

    displaySimilar = false;
    displayWith = false;
    similarType = "0";
    withType = "0";
    similar = [];
    withArray = [];
    similarCategory = null;
    withCategory = null;
    similarProducts = [];
    withProducts = [];

    pushToBreadcrumb(breadcrumb) {
        breadcrumb.map(item => this.breadcrumb.push({ link: item[2] === "category" ? "/shop/catalog/category/" + item[0] : "/shop/catalog/product/" + item[0], value: item[1] }))
    }

    getProduct(productID=0, type="product") {
        let url = "/shop/product/"
        if(type === "product") {
            url = url + productID
        } else {
            url = url + "0?create=" + productID
        }
        instance.get(url)
            .then(success => {
                console.log(success.data.data)
                this.product = success.data.data
                this.product.images = success.data.data.photo !== null ? success.data.data.photo : []

                if(success.data.data.breadcrumb) {
                    this.pushToBreadcrumb(success.data.data.breadcrumb)
                }
                if(success.data.data.productid && success.data.data.productid > 0) {
                    this.pushToBreadcrumb([[  success.data.data.productid, "Главный товар", "product" ]])
                }

                if(success.data.data.similar !== null) {
                    if(success.data.data.similar.category && success.data.data.similar.category >= 0) {
                        this.setSimilarType("category");
                        this.setSimilarCategory(success.data.data.similar.category);
                    } else if(success.data.data.similar.product) {
                        this.setSimilarType("product");
                        this.setSimilarProducts(success.data.data.similarproduct);
                        this.setSimilar(success.data.data.similar.product);
                    } else {
                        this.setSimilarType("0");
                        this.setSimilar([]);
                        this.setSimilarProducts([]);
                    }
                } else {
                    this.setSimilarType("0");
                    this.setSimilar([]);
                    this.setSimilarProducts([]);
                }

                if(success.data.data.withproduct !== null) {
                    // console.log("РЕКОМЕНДУЕМЫЕ!")
                    // response.data.data.listwith = JSON.parse(response.data.data.listwith)

                    if(success.data.data.listwith && success.data.data.listwith.category && success.data.data.listwith.category >= 0) {
                        this.setWithType("category");
                        this.setWithCategory(success.data.data.listwith.category);
                    } else if(success.data.data.listwith && success.data.data.listwith.product) {
                        this.setWithType("product");
                        this.setWithProducts(success.data.data.withproduct);
                        this.setWith(success.data.data.listwith.product);
                    } else {
                        this.setWithType("0");
                        this.setWith([]);
                        this.setWithProducts([]);
                    }
                } else {
                    this.setWithType("0");
                    this.setWith([]);
                    this.setWithProducts([]);
                }

                if(type !== "product") {
                    this.product.parentid = productID
                }

                pc.getPropertiesByCategory(success.data.data.parentid)
            })
            .catch(error => console.log(error))
    }

    deleteProduct(productID) {
        if(window.confirm("Вы уверены, что хотите удалить товар?")) {
            instance.delete("/shop/product/" + productID)
                .then(success => {
                    console.log(success)
                })
                .catch(error => {
                    console.log(error)
                })
        }

    }

    deleteProductSub(productID) {
        if(window.confirm("Вы уверены, что хотите удалить товар?")) {
            if(this.product.sub[1] === 1) {
                this.product.viewvariation = 0;
                this.saveProduct(0)
            }
            instance.delete("/shop/product/" + productID)
                .then(success => {
                    this.getProduct(this.product.id)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    setProduct(name, value) {
        this.product[name] = value
    }

    setTab(tab) {
        this.tab = tab
    }

    saveProduct(variant=0) {

        if(this.product.priceold > 0 && this.product.price > this.product.priceold) {
            message.error('Новая цена не может быть выше старой');
            return false
        } else {

            if(this.product.price <= 0) {
                message.error('Заполните поле Окончательная цена');
                return false
            }

            let data = new FormData();
            data.append('name', this.product.name);
            data.append('viewvariation', variant > 0 ? 1 : this.product.viewvariation)
            data.append('view', this.product.view);
            data.append('parentid', this.product.parentid);
            data.append('price', this.product.price);
            data.append('priceold', this.product.priceold > 0 ? this.product.priceold : 0);
            data.append('priceuser', this.product.priceuser ? this.product.priceuser : 0);
            data.append('priceuserold', this.product.priceuserold > 0 ? this.product.priceuserold : 0);
            data.append('type', this.product.type);
            data.append('count', this.product.count);
            data.append('id_crm', this.product.id_crm);
            data.append('pictures', JSON.stringify(this.product.images));

            this.product.filtervalueid && this.product.filtervalueid.length > 0 && this.product.filtervalueid.map(item => {
                data.append('filtervalid[]', item);
            })


            if(this.similarType === "0") {
                data.append('similar', "0");
            } else if(this.similarType === "product") {
                if(this.similar.length > 0) {
                    data.append('similar', JSON.stringify({"product": this.similar}));
                } else {
                    data.append('similar', "0");
                }
            } else if(this.similarType === "category") {
                data.append('similar', JSON.stringify({"category": this.similarCategory}));
            }

            if(this.withType === "0") {
                data.append('with', "0");
            } else if(this.withType === "product") {
                if(this.withArray.length > 0) {
                    data.append('with', JSON.stringify({"product": this.withArray}));
                } else {
                    data.append('with', "0");
                }
            } else if(this.withType === "category") {
                data.append('with', JSON.stringify({"category": this.withCategory}));
            }

            // if(products.withArray.length > 0) {
            //     data.append('with', JSON.stringify(products.withArray));
            // }

            data.append("hash", hashController.setHash(data))
            data.append('text', this.product.text ? this.product.text : "");

            sender.post('/product/product/' + this.product.id, data)
                .then(response => {
                    if(response.status === 201) {
                        document.location.href="/shop/catalog/category/" + response.data.parentid
                    } else {
                        notification.success({
                            message: 'Готово',
                            description: 'Товар успешно изменен'
                        })

                        window.location.href = `/shop/catalog/product/${this.product.id}`
                    }
                })
                .catch(error => {
                    console.log(error.response)
                    notification.error({
                        message: 'Ошибка',
                        description: error.response.data.message
                    })
                })
        }



    }

    sendForm(event) {
        event.preventDefault()
        this.saveProduct()
    }

    getProductsByFilter() {
        console.log(this.search);
        let url = "/shop/search?limit=" + this.limit + "&page=" + this.page;

        if(this.search.id > 0) {
            url = url + "&id=" + this.search.id;
        } else if(this.search.id_crm !== null) {
            url = url + "&id_crm=" + this.search.id_crm;
        } else if(this.search.name !== "") {
            url = url + "&name=" + this.search.name;
        }

        instance.get(url)
            .then(success => {
                console.log(success);
                this.products = success.data.data.products[0];
                this.pagination.page = success.data.data.pagination[0];
                this.pagination.all = success.data.data.pagination[1];
            });
    }

    onClickPage(page) {
        this.page = page;
        this.getProductsByFilter();
    }

    setSimilar(array) {
        this.similar = array;
    }

    openSimilarModal() {
        this.displaySimilar = true;
        this.getProductsByFilter();
    }

    closeSimilarModal() {
        this.displaySimilar = false;
    }

    addToSimilar(productID) {
        this.similar = [...this.similar, productID];
        this.getProductsByFilter();
    }

    deleteFromSimilar(productID) {
        this.similar = this.similar.filter(el => el !== productID);
        this.getProductsByFilter();
    }

    setWith(array) {
        this.withArray = array;
    }

    openWithModal() {
        this.displayWith = true;
        this.getProductsByFilter();
    }

    closeWithModal() {
        this.displayWith = false;
    }

    addToWith(productID) {
        this.withArray = [...this.withArray, productID];
        this.getProductsByFilter();
    }

    deleteFromWith(productID) {
        this.withArray = this.withArray.filter(el => el !== productID);
        this.getProductsByFilter();
    }

    setSimilarType(type) {
        this.similarType = type;
    }

    setSimilarCategory(categoryID) {
        this.similarCategory = categoryID;
    }

    setSimilarProducts(array) {
        this.similarProducts = array;
    }

    setWithType(type) {
        this.withType = type;
    }

    setWithCategory(categoryID) {
        console.log(categoryID)
        this.withCategory = categoryID;
    }

    setWithProducts(array) {
        this.withProducts = array;
    }

    onChange(name, value) {
        this.search[name] = value;
        this.getProductsByFilter();
    }

    deletePicture(path) {
        if(this.product.id && this.product.id > 0) {
            deleteFile("product", path, Number(this.product.id))
        }
        this.product.images = this.product.images.filter(el => el.path !== path)
    }

    setFilters(filterID) {

        let filterCategoryID = 0

        pc.product.map(item => {
            item.filtervalue.map(el => {
                if(el.id === filterID) {
                    filterCategoryID = item.id
                    return false
                }
            })
        })

        let arr = this.product.filtervalue

        console.log(Object.keys(arr).length)

        if(arr[filterCategoryID]) {
                let mass = arr[filterCategoryID]
                if(mass.find(el => el === filterID)) {
                    mass = mass.filter(el => el !== filterID)
                } else {
                    mass.push(filterID)
                }

                arr[filterCategoryID] = mass
        } else {
            arr = {...arr, [filterCategoryID]: [filterID]}
            //arr[filterCategoryID] = [filterID]
        }

        console.log(arr)

        let values = []

        for(let item in arr) {
            arr[item].map(el => values.push(el))
        }

        this.product.filtervalue = arr
        this.product.filtervalueid = values
    }

    createSub() {
        this.saveProduct(1)
        let data = new FormData();
        data.append("productid", this.product.id)
        data.append('name', this.product.name + " (Копия)");
        data.append('text', this.product.text);
        data.append('view', this.product.view);
        data.append('isvariant', 1);
        data.append('parentid', this.product.parentid);
        data.append('price', this.product.price);
        data.append('priceold', this.product.priceold);
        data.append('priceuser', this.product.priceuser);
        data.append('priceuserold', this.product.priceuserold);
        data.append('type', this.product.type);
        data.append('count', this.product.count);
        data.append('viewvariation', 0);
        data.append('id_crm', this.product.id_crm);
        data.append('pictures', JSON.stringify(this.product.images));

        this.product.filtervalueid.length > 0 && this.product.filtervalueid.map(item => {
            data.append('filtervalid[]', item);
        })

        data.append("hash", hashController.setHash(data))

        sender.post('/product/product/0', data)
            .then(response => {
                this.getProduct(this.product.id)
            })
            .catch(error => console.log(error))
    }

    setModerationProductByProductID(productID, status) {
        let data = new FormData()
        data.append("view", status)
        data.append("hash", hashController.setHash(data))

        sender.post('/product/product/' + productID, data)
            .then(response => {
                console.log(response)
                notification.success({
                    message: 'Готово',
                    description: 'Товар успешно изменен'
                })
                this.getProductsToModeration()
            })
            .catch(error => {
                console.log(error)
                notification.error({
                    message: 'Ошибка',
                    description: error.response.data.message
                })
            })
    }

    getProductsToModeration() {
        sender.get("/product/product/?filter[view]=0&page=" + this.pagination.page)
            .then(success => {
                console.log(success)
                this.products = success.data.data.products[0]
                this.pagination.page = success.data.data.pagination[0];
                this.pagination.all = success.data.data.pagination[1];
            })
            .catch(error => console.log(error))
    }

    setPageModeration(value) {
        this.pagination.page = value
        this.getProductsToModeration()
    }

    byField(field) {
        return (a, b) => a[field] > b[field] ? 1 : -1;
    }

    sortImages() {
        this.product.images = this.product.images.sort(this.byField('sort'));
    }

    thisMainPhoto(url) {
        let _this = []
        let __this = {}
        this.product.images.map(item => {
            if(item.path !== url) {
                item.sort = 100
                _this.push(item)
            } else {
                item.sort = 1
                __this = item
            }
        })
        _this.unshift(__this)
        this.product.images = _this
    }
}

export default new shopProductController()