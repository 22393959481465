import { makeAutoObservable } from "mobx"
import sender from "../../../../template/controllers/sender";

class _elements {

    type = ""
    list = []
    pagination = {
        offset: 0,
        limit: 10,
        count: 0
    }

    modal = false

    constructor() {
        makeAutoObservable(this)
    }

    setType(type) {
        this.type = type
        this.get()
    }

    setOffset(value) {
        this.pagination.offset = (value * this.pagination.limit)-this.pagination.limit
        this.get()
    }

    getUrl(parentID=0) {
        let url = ""

        if(this.type === "news") {
            url = "/article/news"
        } else {
            url = `/product/category/${parentID}`
        }

        return url
    }

    getProducts(parentID) {
        sender.get(`/product/product/${parentID}?limit=${this.pagination.limit}&offset=${this.pagination.offset}`)
            .then(response => {
                console.log(response)
                this.list = response.data.data.products[0]
                this.pagination.count = response.data.data.products[1]
                this.pagination.offset = response.data.data.pagination[3]
                this.modal = true
            })
            .catch(response => {
                console.log("Error")
            })
    }

    change(e) {
        this.type = e
        this.get()
    }

    get(parentID=0) {
        sender.get(`${this.getUrl(parentID)}?limit=0&offset=0`)
            .then(response => {
                console.log(response)
                if(response.data.category) {
                    this.list = response.data.category
                } else {
                    this.list = response.data
                }
                this.modal = true
            })
            .catch(response => {
                console.log("Error")
            })
    }

    close() {
        this.type = ""
        this.list = []
        this.pagination = {
            offset: 0,
            limit: 10,
            count: 0
        }

        this.modal = false
    }

}

export default new _elements()