import { observer } from "mobx-react-lite"
import BlockTitle from "../BlockTitle";
import _main from "../../controllers/_main.screen.controller"
import getModule from "../../../../../helpers/getModule";
import {useEffect} from "react";

const MainModuleButton = observer(({ item }) => {

    return(
        <div>
            <div>
                <h5>{item.ksbuttontext.name}</h5>
                <p>{item.ksbuttontext.text}</p>

                <div className="props">
                    <p><span>Текст кнопки: </span>{item.ksbuttontext.buttontext}</p>
                    <p><span>Тип ссылки: </span>{_main.typeLink(item.ksbuttontext.typelink, item.ksbuttontext.link).title}</p>
                    {item.ksbuttontext.element && <p><span>Назначение ссылки: </span>#{item.ksbuttontext.element.id} | {item.ksbuttontext.element.name}</p>}
                </div>
            </div>
        </div>
    )
})

export default MainModuleButton