import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {Button, Col, Pagination, Row, Space, Table, Tag, Tooltip, Select, Slider, DatePicker, Divider} from "antd";
import orderController from "../order.controller";
import React, {useEffect} from "react";
import {SettingOutlined} from "@ant-design/icons";
import loaderController from "../../../template/loader/loader.controller";
import LoaderPage from "../../../template/loader/LoaderPage";
import TemplateTitle from "../../../template/title/TemplateTitle"
import moment from 'moment';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

const OrderOrders = observer(() => {
    const { t } = useTranslation();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('orders:status'),
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                let status_text;
                let status_color;
                if(text === 'accepted') {
                    status_text = t('orders:accepted');
                    status_color = 'green';
                } else if(text === 'new') {
                    status_text = t('orders:new');
                    status_color = 'blue';
                } else if(text === 'rejected') {
                    status_text = t('orders:rejected');
                    status_color = 'red';
                }
                return(
                    <>
                        {text.id && text.id > 0 ? <Tag color={"#" + text.colorhex}>{text.name}</Tag> : <Tag color={status_color}>{status_text}</Tag>}
                    </>
                )
            }
        },
        {
            title: t('orders:client'),
            dataIndex: 'userid',
            key: 'userid',
            render: text => (
                <a href={"/users/" + text}>{text}</a>
            )
        },
        {
            title: t('orders:price'),
            dataIndex: 'summ',
            key: 'summ',
        },
        {
            title: t('orders:statusPay'),
            dataIndex: 'paystatus',
            key: 'paystatus'
        },
        {
            title: t('orders:created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: t('orders:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <Tooltip title={t('orders:view')}><Button type="dashed" shape="circle" href={"/shop/orders/" + record.id} icon={<SettingOutlined />} /></Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    useEffect(() => {
        loaderController.onChange(true)
        orderController.getFilters()
        orderController.getOrders()
    }, [])

    const breadcrumb = [
        { link: "/shop", value: t('orders:shop') },
        { link: null, value: t('orders:orders') }
    ]

    return(
        <>
            <TemplateTitle
                title={t('orders:orders')}
                breadcrumb={breadcrumb}
            />

            <OrderFilters />

            <Table columns={columns} dataSource={orderController.orders} pagination={false} defaultPageSize={orderController.pagination[2]} />
            <br/>
            <Pagination
                defaultCurrent={orderController.pagination[0]}
                total={orderController.pagination[1]}
                showSizeChanger={false}
                onChange={(e) => orderController.setOffset(e)}
            />
            {loaderController.load && <LoaderPage />}
        </>
    )
})

const OrderFilters = observer(() => {

    return(
        <>
            <Divider />
            <Row gutter={[20, 20]} justify="space-between" align="bottom">
                <Col span={24}>
                    <p className="sub">Фильтр</p>
                </Col>
                <Col span={6}>
                    <label>Статус заказа</label>
                    <Select
                        style={{ width: `100%` }}
                        options={orderController.filter.status.map(el => ({
                            label: el.name,
                            value: el.id
                        }))}
                        value={orderController.filter_orders.status}                        onChange={(e) => orderController.filter_orders.status = e}
                        mode="multiple"
                    />
                </Col>
                <Col span={6}>
                    <label>Статус оплаты</label>
                    <Select
                        style={{ width: `100%` }}
                        options={orderController.filter.status_pay.map(el => ({
                            label: el,
                            value: el
                        }))}
                        value={orderController.filter_orders.status_pay}                        onChange={(e) => orderController.filter_orders.status_pay = e}
                        mode="multiple"
                    />
                </Col>
                <Col span={6}>
                    <label>Разрешено закрытие сделки</label>
                    <Select
                        style={{ width: `100%` }}
                        value={orderController.filter_orders.dealcloseallowed}
                        options={[
                            {label: 'Да', value: 1},
                            {label: 'Нет', value: 0}
                        ]}
                        mode="multiple"
                        onChange={(e) => orderController.filter_orders.dealcloseallowed = e}
                    />
                </Col>
                <Col span={6}>
                    <label>Разрешена ли выплата</label>
                    <Select
                        style={{ width: `100%` }}
                        value={orderController.filter_orders.paymentsellerallowed}
                        options={[
                            {label: 'Да', value: 1},
                            {label: 'Нет', value: 0}
                        ]}
                        mode="multiple"
                        onChange={(e) => orderController.filter_orders.paymentsellerallowed = e}
                    />
                </Col>
                <Col span={6}>
                    <label>Подтверждена ли сделка с продавцом</label>
                    <Select
                        style={{ width: `100%` }}
                        value={orderController.filter_orders.dealactive}
                        options={[
                            {label: 'Да', value: 1},
                            {label: 'Нет', value: 0}
                        ]}
                        mode="multiple"
                        onChange={(e) => orderController.filter_orders.dealactive = e}
                    />
                </Col>
                <Col span={6}>
                    <label>Сумма</label>
                    <Slider
                        min={orderController.filter.sum.min_sum}
                        max={orderController.filter.sum.max_sum}
                        value={orderController.filter_orders.sum}
                        onChange={(e) => orderController.filter_orders.sum = e}
                        range
                    />
                </Col>
                <Col span={6}>
                    <label>Дата создания</label>
                    <DatePicker.RangePicker
                        locale={locale}
                        style={{ width: `100%` }}
                        value={orderController.filter_orders.created_at}
                        disabledDate={(current) => {
                            return current && (current < moment.unix(orderController.filter.created_at.min_date) || current > moment.unix(orderController.filter.created_at.max_date));
                        }}
                        onChange={(e) => orderController.filter_orders.created_at = e}
                    />
                </Col>
                <Col span={6}>
                    <Button block type="dashed" onClick={() => orderController.getOrdersToFilter()}>Применить</Button>
                </Col>
            </Row>
            <Divider />
        </>
    )
})

export default OrderOrders