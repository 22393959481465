import { observer } from "mobx-react-lite"
import _elements from "../../modules/modules/main/controllers/helpers/_elements.helper"
import { Table, Image, Checkbox, Space, Tooltip, Button, Pagination, Row, Col, Input, InputNumber } from "antd"
import { DeleteOutlined, SettingOutlined } from "@ant-design/icons"
import productsHelper from "./controllers/products.helper"
import { useEffect } from "react"
import _main from "../../modules/modules/main/controllers/_main.screen.controller"

const SearchBlock = observer(() => {

    return(
        <>
            <Row justify="space-around" align="middle" gutter={[8, 8]}>
                <Col span={16}>
                    <Input
                        placeholder="Наименование"
                        value={productsHelper.filter.name}
                        onChange={(e) => productsHelper.onChangeFilter("name", e.target.value)}
                    />
                </Col>
                <Col span={4}>
                    <InputNumber
                        placeholder="ID товара"
                        value={productsHelper.filter.id}
                        style={{ width: 100 + "%" }}
                        onChange={(e) => productsHelper.onChangeFilter("id", e)}
                    />
                </Col>
                <Col span={4}>
                    <Input
                        placeholder="Артикул"
                        value={productsHelper.filter.id_crm}
                        style={{ width: 100 + "%" }}
                        onChange={(e) => productsHelper.onChangeFilter("id_crm", e ? e.target.value : '')}
                    />
                </Col>
            </Row>
        </>
    )
})

const ProductsListHelper = observer(({ actions=[], selected=[] }) => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: "Артикул",
            dataIndex: 'id_crm',
            key: 'id_crm'
        },
        {
            title: "Изображение",
            dataIndex: 'photo',
            key: 'photo',
            render: text => {
                if(text !== "[]") {
                    text = JSON.parse(text);
                    return(
                        <>
                            <Image
                                width={'100px'}
                                src={text[0].path}
                            />
                        </>
                    )
                }
            },
        },
        {
            title: "Наименование",
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: "Стоимость",
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: "Кол-во",
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: "Действия",
            key: 'action',
            align: 'right',
            render: (text, record) => {

                let photo = ""
                if(record.photo !== "[]") {
                    photo = JSON.parse(record.photo)[0].path
                }

                return(
                    <>
                        <Space>
                            {actions.length > 0 && actions.map(item => (
                                <>
                                    {item.type && item.type !== ""
                                        ?   selected.length > 0 && selected.findIndex(el => Number(el) === Number(record.id)) > -1
                                            ? <Button danger onClick={() => _main.deleteProductFromArray(record.id, "ksblockproduct")}>Убрать</Button>
                                            : <Button onClick={() => _main.addProductToArray(record, "ksblockproduct")}>{ item.title }.</Button>
                                        :   <>
                                        {selected.length > 0 && Number(selected[0]) === record.id
                                            ?   <Button type="primary">Выбрано</Button>
                                            :   <Space>
                                                    <Button onClick={() => item.refs(record.id)}>{ item.title }</Button>
                                                    <Button
                                                        onClick={() => { item.refs(record.id); item.refImage(photo)}}
                                                    >
                                                        Выбрать с фото
                                                        </Button>
                                                </Space>
                                        }
                                        </>
                                    }
                                </>
                            ))}
                        </Space>
                    </>
                )
            }
        },
    ]

    useEffect(() => {
        productsHelper.get()
    }, [])

    return(
        <>
            <SearchBlock />
            <Table columns={columns} dataSource={productsHelper.list} pagination={false} />
            <br/><br/>
            <Pagination
                defaultCurrent={productsHelper.pagination.page}
                total={productsHelper.pagination.count}
                showSizeChanger={false}
                onChange={e => productsHelper.setPage(e)}
                pageSize={productsHelper.pagination.limit}
                current={productsHelper.pagination.page}
            />
        </>
    )
})

export default ProductsListHelper