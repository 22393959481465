import {makeAutoObservable} from "mobx";
import instance from "../../../store/axios";
import {notification} from "antd";
import loaderController from "../../template/loader/loader.controller";
import deliveryController from "../delivery/controllers/interval.controller"
import sender from '../../template/controllers/sender';
import moment from 'moment';

class orderController {

    constructor() {
        makeAutoObservable(this)
    }

    order = false
    orders = []
    offset = 1
    pagination = 0

    statuses = []
    status = {}

    filter = {
        status: [],
        status_pay: [],
        sum: {
            min_sum: 0,
            max_sum: 10000
        },
        created_at: {
            min_date: 0,
            max_date: 0
        },
        dealactive: [],
        paymentsellerallowed: [],
        dealcloseallowed: [],
    }

    filter_orders = {
        status: [],
        status_pay: [],
        sum: [],
        created_at: [],
        dealactive: [],
        paymentsellerallowed: [],
        dealcloseallowed: [],
    }

    disabledDate = null

    deal = null

    getFilters() {
        instance.get('/shop/orders/?page=' + this.offset)
            .then(response => {
                this.filter = response.data.filter

                this.filter_orders.sum = [response.data.filter.sum.min_sum, response.data.filter.sum.max_sum]
            })
    }

    getOrderByID(orderID) {

        deliveryController.getIntervalForOrder()

        this.getDeal(orderID)

        instance.get('/shop/order/' + orderID)
            .then(success => {
                console.log(success)
                let priceProducts = 0;
                success.data.data.products.map(item => {
                    priceProducts = priceProducts + (item.price * item.count)
                })
                success.data.data.priceProducts = priceProducts
                let date = new Date(success.data.data.created_at * 1000)
                success.data.data.created_at = date.getDate()+
                    "."+(date.getMonth()+1)+
                    "."+date.getFullYear()+
                    " "+date.getHours()+
                    ":"+date.getMinutes()+
                    ":"+date.getSeconds();

                let dateDelivery = 0
                let dateDate = ""
                let dateTime = ""

                if(success.data.data.datedelivery && success.data.data.datedelivery > 0) {
                    dateDelivery = new Date(success.data.data.datedelivery * 1000)

                    dateDate = dateDelivery.getDate() + "." + (dateDelivery.getMonth()+1) +  "." + dateDelivery.getFullYear()
                    console.log(dateDate)
                    console.log(dateDelivery.getUTCHours() + ":" + dateDelivery.getUTCMinutes())
                    let resp = deliveryController.intervalOrder
                    let allSeconds = resp.timeenddelivery - resp.timestartdelivery
                    let countIntervals = allSeconds / resp.timeinterval

                    let intervals = []
                    let arrr = resp.timestartdelivery - resp.timeinterval

                    for(let i=0;i < countIntervals; i++) {
                        arrr = arrr + resp.timeinterval
                        if(arrr > resp.timeenddelivery) {
                            let old = arrr - resp.timeenddelivery
                            arrr = arrr - old
                        }
                        let result = deliveryController.convertFromSecond(arrr)
                        result = result.split(":")
                        intervals.push([ Number(result[0]), Number(result[1]) ])
                    }

                    console.log(intervals)


                    let itemTrue = "Неопределен"
                    intervals.map(item => {
                        if(dateDelivery.getUTCHours() >= item[0] && dateDelivery.getUTCMinutes() >= item[1]) {
                            itemTrue = item
                        }
                    })

                    console.log(itemTrue)

                    let itemTrueSeconds = deliveryController.convertToSeconds(itemTrue[0] + ":" + itemTrue[1])

                    itemTrueSeconds = itemTrueSeconds + resp.timeinterval

                    if(itemTrueSeconds > resp.timeenddelivery) {
                        let old = itemTrueSeconds - resp.timeenddelivery
                        itemTrueSeconds = itemTrueSeconds - old
                    }

                    itemTrueSeconds = deliveryController.convertFromSecond(itemTrueSeconds)

                    dateTime = itemTrue[0] + ":" + itemTrue[1] + " - " + itemTrueSeconds




                }

                success.data.data.customDateDelivery = {
                    date: dateDate,
                    time: dateTime
                }

                success.data.data.countAll = 0
                success.data.data.products.map(item => success.data.data.countAll = success.data.data.countAll + item.count)

                this.order = success.data.data
            });
    }

    getOrdersToFilter() {
        this.offset = 1
        this.getOrders()
    }

    getOrders() {
        let uri = `/shop/orders?page=${this.offset}`

        if(this.filter_orders.sum.length > 0) {
            uri += `&sumfrom=${this.filter_orders.sum[0]}&sumto=${this.filter_orders.sum[1]}`
        }

        if(this.filter_orders.created_at.length > 0) {
            uri += `&datefrom=${moment(this.filter_orders.created_at[0]).format("DD.MM.YYYY")}&dateto=${moment(this.filter_orders.created_at[1]).format("DD.MM.YYYY")}`
        }

        if(this.filter_orders.dealactive.length > 0) {
            this.filter_orders.dealactive.map((el, i) => uri += `&dealactive[${i}]=${el}`)
        }

        if(this.filter_orders.paymentsellerallowed.length > 0) {
            this.filter_orders.paymentsellerallowed.map((el, i) => uri += `&paymentsellerallowed[${i}]=${el}`)
        }

        if(this.filter_orders.dealcloseallowed.length > 0) {
            this.filter_orders.dealcloseallowed.map((el, i) => uri += `&dealcloseallowed[${i}]=${el}`)
        }

        if(this.filter_orders.status.length > 0) {
            this.filter_orders.status.map((el, i) => uri += `&status[${i}]=${el}`)
        }

        if(this.filter_orders.status_pay.length > 0) {
            this.filter_orders.status_pay.map((el, i) => uri += `&status_pay[${i}]=${el}`)
        }

        console.log(uri)

        instance.get(uri)
            .then(response => {
                console.log(response);
                this.orders = response.data.data
                this.pagination = response.data.pagination
                loaderController.onChange(false)
            })
    }

    setOrder(status) {
        console.log(status)
        this.order.status = {id: status}
    }

    setStatusByOrderID() {
        let facts = new FormData();

        facts.append('status', this.order.status.id)
        facts.append('tracknumber', this.order.tracknumber)
        facts.append('paymentsellerallowed', `${+ this.order.paymentsellerallowed}` ?? 0)
        facts.append('dealcloseallowed', `${+ this.order.dealcloseallowed}` ?? 0)
        facts.append('dealactive', `${+ this.order.dealactive}` ?? 0)


        instance.post(`/shop/order/${this.order.id}`, facts)
            .then(response => {
                console.log(response)
                //window.location.href = `/shop/orders/${this.order.id}`
            })
    }

    getStatuses() {
        instance.get('/shop/statusorder/')
            .then(response => {
                console.log(response);
                this.statuses = response.data.data[0]
            })
    }

    selectStatus(statusID) {
        this.status = this.statuses.find(el => el.id === statusID);
    }

    onChangeStatus(key, value) {
        console.log(key)
        console.log(value)
        this.status[key] = value
    }

    clearStatus() {
        this.status = {}
    }

    setStatus(e) {
        e.preventDefault()

        let facts = new FormData()
        facts.append("name", this.status.name)
        facts.append("colorhex", this.status.colorhex)
        facts.append("icon", this.status.icon)

        let url = "/shop/statusorder/";
        if(this.status.id && this.status.id > 0) {
            url = url + this.status.id
        }

        instance.post(url, facts)
            .then(success => {
                console.log(success)
                this.clearStatus()
                this.getStatuses()
            })
            .catch(error => {
                console.log(error)
            })
    }

    setOffset(e) {
        this.offset = e
        this.getOrders()
    }

    statusCreate() {
        this.clearStatus()
        this.status = {
            id: 0,
            name: "",
            colorhex: "7264D6"
        }
        this.statuses.unshift(this.status)
    }

    deleteStatus(statusID) {
        instance.delete("/shop/statusorder/" + statusID)
            .then(success => {
                console.log(success)
                this.clearStatus()
                this.getStatuses()
            })
            .catch(error => {
                console.log(error)
            })
    }

    getDeal(orderId) {
        sender.get(`/product/ordergive/${orderId}`)
            .then(result => {
                console.log(result)
                this.deal = result.data
            })
    }
}

export default new orderController()