import { observer } from "mobx-react-lite"
import { Row, Col, Checkbox, Input, InputNumber, Select, Upload, Tooltip, Button } from "antd"
import _main from "../../controllers/_main.screen.controller"
import getLoader from "../../../../../store/uploadFiles.controller";
import {DeleteOutlined, InboxOutlined} from "@ant-design/icons";
import DeleteImage from "../../controllers/helpers/deleteImage";
import TypeLink from "../TypeLink";

const MainModuleStoryModal = observer(() => {

    let el = ""

    const updateData = (value) => {
        if(value.path && value.path !== "") {
            _main.set("photo", el, value.path)
        }
    }

    return(
        <Row gutter={16}>
            <Col span={24}>
                <Checkbox
                    checked={Number(_main.selected.active) > 0}
                    onChange={(e) => _main.set("active", el, e.target.checked ? 1 : 0)}
                >
                    Активность
                </Checkbox>
            </Col>
            <Col span={24} className="gutter-row">
                <label htmlFor="name" className="all-label">Наименование</label>
                <Input
                    value={_main.selected.name}
                    id="name"
                    onChange={(e) => _main.set("name", el, e.target.value)}
                />
            </Col>

            <Col span={24}>
                <label className="all-label">{_main.selected.photo ? "Изменить изображение" : "Загрузить изображение"}</label>
                <Upload.Dragger
                    multiple={false}
                    fileList={[]}
                    customRequest={e => getLoader(e, "images", "file", "story", updateData)}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Загрузить</p>
                </Upload.Dragger>
            </Col>
            <Col span={24} className="gutter-row">
                {_main.selected.photo &&
                <>
                    <label className="all-label">Изображение</label>
                    <div className="product-item_picture">
                        <img src={_main.selected.photo} alt="" />
                        {_main.selected.id && _main.selected.id > 0 &&
                        <DeleteImage path={_main.selected.photo} id={_main.selected.id} type="story" />
                        }
                    </div>
                </>
                }
            </Col>
        </Row>
    )
})

export default MainModuleStoryModal