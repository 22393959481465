import { observer } from "mobx-react-lite"
import _main from "../../controllers/_main.screen.controller"
import { Table, Space, Tooltip, Button, Switch, Row, Col } from "antd"
import {ArrowDownOutlined, ArrowUpOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons"

const MainModuleVideo = observer(({ item }) => {

    const columns = [
        {
            title: 'Обложка',
            dataIndex: 'photo',
            key: 'photo',
            render: text => (
                <img src={text} alt="" width={`150px`} />
            )
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Ссылка на видео',
            dataIndex: 'video',
            key: 'video'
        },
        {
            title: 'Действия',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">

                    <Tooltip title="Активность">
                        <Switch size="small" checked={record.active > 0} onChange={e => _main.editActive(record.id, "ksvideo", e, () => _main.get())} />
                    </Tooltip>
                    <Tooltip title="Изменить">
                        <Button
                            size="small" shape="circle"
                            onClick={() => _main.selectVideo(record)}
                            icon={<EditOutlined />}
                        />
                    </Tooltip>
                    <Tooltip title="Переместить выше">
                        <Button
                            shape="circle" size="small" icon={<ArrowUpOutlined />}
                            onClick={() => _main.editPosition(record.id, "ksvideo", "moveUp", "parentid", () => _main.get())}
                        />
                    </Tooltip>
                    <Tooltip title="Переместить ниже">
                        <Button
                            shape="circle" size="small" icon={<ArrowDownOutlined />}
                            onClick={() => _main.editPosition(record.id, "ksvideo", "moveDown", "parentid", () => _main.get())}
                        />
                    </Tooltip>
                    <Tooltip title="Удалить видео">
                        <Button
                            shape="circle" size="small" danger icon={<DeleteOutlined />}
                            onClick={() => _main.delete("ksvideo", record.id, () => _main.get())}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return(
        <div>
            <div>
                <Row justify="space-around" align="middle" gutter={[20, 20]}>
                    <Col span={12}>
                        <h4>{item.ksvideocategory.name}</h4>
                    </Col>
                    <Col span={12} align="right">
                        <Button
                            type="primary"
                            shape="round"
                            onClick={() => _main.selectVideo({}, item.ksvideocategory.id)}
                        >
                            Добавить видео
                        </Button>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={item.ksvideocategory.ksvideo} pagination={false} />


            </div>
        </div>
    )
})

export default MainModuleVideo