import { observer } from "mobx-react-lite"
import { Card } from 'antd'
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons'

const Element = observer(({ item }) => {

    return(
        <Card
            style={{ width: 300 }}
            cover={
                <img
                    alt="example"
                    src={item.image}
                />
            }
            actions={[
                <SettingOutlined key="setting" />,
                <EditOutlined key="edit" />,
                <EllipsisOutlined key="ellipsis" />,
            ]}
        >
            <Card.Meta
                title="Card title"
                description="This is the description"
            />
        </Card>
    )
})

const MainModuleListElements = observer(({ list=[], config=[] }) => {
    return config.map(item => (
        <Card
            style={{ width: 300 }}
            actions={[
                <SettingOutlined key="setting" />,
                <EditOutlined key="edit" />,
                <EllipsisOutlined key="ellipsis" />,
            ]}
        >

            {list.findIndex(el => el[item.dataIndex]) > -1 && item.render
                ?   item.render( list.find(el => el[item.dataIndex])[item.dataIndex], list.find(el => el[item.dataIndex]) )
                :   <>{ list.find(el => el[item.dataIndex])[item.dataIndex] }</>
            }
        </Card>
    ))
})

export default MainModuleListElements