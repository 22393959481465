import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { Table, Button } from "antd"
import _main from "../controllers/_main.screen.controller"

const MainModuleStatuses = observer(({ select=null, refs }) => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Действия',
            key: 'actions',
            render: record => (
                <>
                    {select !== null && record.id === Number(select)
                        ?   <Button type="primary">Выбрано</Button>
                        :   <Button onClick={() => refs(record.id)}>Выбрать</Button>
                    }
                </>
            )
        }
    ]

    useEffect(() => {
        _main.getStatuses()
    }, [])

    return(
        <>
            <Table columns={columns} dataSource={_main.statuses} pagination={false} />
        </>
    )
})

export default MainModuleStatuses