import { makeAutoObservable } from "mobx"
import instance from "../../../store/axios";

class productsHelper {

    constructor() {
        makeAutoObservable(this)
    }

    list = []

    pagination = {
        limit: 10,
        page: 1,
        count: 0
    }

    filter = {
        id: 0,
        id_crm: "",
        name: ""
    }

    get() {
        let url = `/shop/search?limit=${this.pagination.limit}&page=${this.pagination.page}`

        if(this.filter.id > 0)              { url = `${url}&id=${this.filter.id}` }
        else if(this.filter.id_crm && this.filter.id_crm !== "")  { url = `${url}&id_crm=${this.filter.id_crm}` }
        else if(this.filter.name !== "")    { url = `${url}&name=${this.filter.name}` }

        console.log(url)

        instance.get(url)
            .then(response => {
                console.log(response)
                this.list = response.data.data.products[0]
                this.pagination.page = response.data.data.pagination[0]
                this.pagination.count = response.data.data.pagination[1]
            })
            .catch(response => {

            })
    }

    onChangeFilter(name, value) {
        this.filter[name] = value
        this.get()
    }

    setPage(page) {
        this.pagination.page = page
        this.get()
    }
}

export default new productsHelper()