import { observer } from "mobx-react-lite"
import shopProductController from "../controllers/productController"
import {Button, Col, Row, Tooltip, Upload} from "antd";
import {DeleteOutlined, InboxOutlined, StarOutlined} from "@ant-design/icons";
import getLoader from "../../../../../../store/uploadFiles.controller";
import React from "react";
import {useTranslation} from "react-i18next";

const ShopProductImages = observer(() => {

    const { t } = useTranslation();

    const updateData = (value) => {
        let data = shopProductController.product.images && shopProductController.product.images.length > 0 ? shopProductController.product.images : []
        data.push(value)
        shopProductController.setProduct("images", data)
    }

    return(
        <>
            <Row>
                <Col span={24} className="product-input_block">
                    {shopProductController.product.images && shopProductController.product.images.length > 0 &&
                    <Col span={24} className="product-input_block">
                        {shopProductController.product.images.map(item => (
                            <div className="product-item_picture">
                                <img src={item.path} alt="" />
                                <Tooltip title={item.sort && item.sort === 1 ? "Главное фото" : "Сделать главным"}>
                                    <Button
                                        type={item.sort && item.sort === 1 ? "" : "dashed"}
                                        className="picture-sort"
                                        shape="circle"
                                        icon={<StarOutlined />}
                                        onClick={() => shopProductController.thisMainPhoto(item.path)}
                                    />
                                </Tooltip>
                                <Tooltip title={t('product:delete')}>
                                    <Button
                                        danger
                                        type="dashed"
                                        className="picture-delete"
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        onClick={() => shopProductController.deletePicture(item.path)}
                                    />
                                </Tooltip>
                            </div>
                        ))}
                    </Col>
                    }
                </Col>
                <Col span={24} className="product-input_block">
                    <Upload.Dragger
                        multiple={true}
                        fileList={[]}
                        customRequest={e => getLoader(e, "images", "file", "product", updateData)}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{t('product:drop')}</p>
                        <p className="ant-upload-hint">
                            {t('product:multiple')}
                        </p>
                    </Upload.Dragger>
                </Col>
            </Row>
        </>
    )
})

export default ShopProductImages