import { observer } from "mobx-react-lite"
import { Row, Col, Checkbox, Input, InputNumber, Select, Upload, Tooltip, Button } from "antd"
import _main from "../../controllers/_main.screen.controller"
import getLoader from "../../../../../store/uploadFiles.controller";
import {DeleteOutlined, InboxOutlined} from "@ant-design/icons";
import DeleteImage from "../../controllers/helpers/deleteImage";
import TypeLink from "../TypeLink"

const MainModuleStorySlideModal = observer(() => {

    let el = ""

    const updateData = (value) => {
        if(value.path && value.path !== "") {
            _main.set("photo", el, value.path)
        }
    }

    return(
        <Row gutter={16}>
            <Col span={24}>
                <Checkbox
                    checked={Number(_main.selected.active) > 0}
                    defaultChecked={true}
                    onChange={(e) => _main.set("active", el, e.target.checked ? 1 : 0)}
                >
                    Активность
                </Checkbox>
            </Col>
            <Col span={24} className="gutter-row">
                <label htmlFor="name" className="all-label">Наименование</label>
                <Input
                    value={_main.selected.name}
                    id="name"
                    onChange={(e) => _main.set("name", el, e.target.value)}
                />
            </Col>
            <Col span={24} className="gutter-row">
                <label htmlFor="namecolor" className="all-label">Цвет заголовка</label>
                <Input
                    value={_main.selected.namecolor}
                    id="namecolor"
                    onChange={(e) => _main.set("namecolor", el, e.target.value)}
                    required={_main.selected.name && _main.selected.name !== "" ? "required" : ""}
                />
            </Col>
            <Col span={24} className="gutter-row">
                <label className="all-label">Текст</label>
                <Input
                    value={_main.selected.text}
                    onChange={(e) => _main.set("text", el, e.target.value)}
                />
            </Col>
            <Col span={24} className="gutter-row">
                <label className="all-label">Цвет текста</label>
                <Input
                    value={_main.selected.textcolor}
                    onChange={(e) => _main.set("textcolor", el, e.target.value)}
                    required={_main.selected.text && _main.selected.text !== "" ? "required" : ""}
                />
            </Col>

            <Col span={24}>
                <label className="all-label">Кнопка</label>
                <Checkbox
                    checked={Number(_main.selected.button) > 0}
                    onChange={(e) => _main.set("button", el, e.target.checked ? 1 : 0)}
                >
                    {Number(_main.selected.button) > 0 ? "Включена" : "Выключена"}
                </Checkbox>
            </Col>

            {_main.selected.button > 0 &&
                <>
                    <Col span={24} className="gutter-row">
                        <label className="all-label">Цвет кнопки</label>
                        <Input
                            value={_main.selected.buttoncolor}
                            onChange={(e) => _main.set("buttoncolor", el, e.target.value)}
                            required
                        />
                    </Col>
                    <Col span={24} className="gutter-row">
                        <label className="all-label">Текст кнопки</label>
                        <Input
                            value={_main.selected.buttontext}
                            onChange={(e) => _main.set("buttontext", el, e.target.value)}
                            required
                        />
                    </Col>
                    <Col span={24} className="gutter-row">
                        <label className="all-label">Цвет текста кнопки</label>
                        <Input
                            value={_main.selected.buttontextcolor}
                            onChange={(e) => _main.set("buttontextcolor", el, e.target.value)}
                            required
                        />
                    </Col>
                    <TypeLink
                        type={_main.selected.typelink}
                        link={_main.selected.link}
                        refs={(e) => _main.set("link", el, e)}
                        refType={(e) => _main.set("typelink", el, e)}
                        refImage={(e) => _main.set("photo", el, e)}
                        element={_main.selected.element ? _main.selected.element : false}
                    />
                </>
            }



            <Col span={24}>
                <label className="all-label">{_main.selected.photo ? "Изменить изображение" : "Загрузить изображение"}</label>
                <Upload.Dragger
                    multiple={false}
                    fileList={[]}
                    customRequest={e => getLoader(e, "images", "file", "story", updateData)}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Загрузить</p>
                </Upload.Dragger>
            </Col>
            <Col span={24} className="gutter-row">
                {_main.selected.photo &&
                <>
                    <label className="all-label">Изображение</label>
                    <div className="product-item_picture">
                        <img src={_main.selected.photo} alt="" />
                        {_main.selected.id && _main.selected.id > 0 &&
                            <DeleteImage path={_main.selected.photo} id={_main.selected.id} type="story" />
                        }
                    </div>
                </>
                }
            </Col>
            <Col span={24}>
                <label className="all-label">Формат отображения изображения</label>
                <Select onChange={(e) => _main.set("imagedisplaystory", el, e)} value={_main.selected.imagedisplaystory} style={{ width: "100%" }}>
                    <Select.Option value={1}>Растягивать пропорционально</Select.Option>
                    <Select.Option value={2}>Показывать все фото</Select.Option>
                </Select>
            </Col>
        </Row>
    )
})

export default MainModuleStorySlideModal