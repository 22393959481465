import sender from "../modules/template/controllers/sender"
import hashController from "../modules/template/controllers/hash.controller"

/**
 * Изменение position элемента в списке
 *
 * @param location - Класс сортируемого элемента
 * @param action - Действие: moveUp или moveDown
 * @param elementID - ID сортируемого элемента
 * @param keyElement -
 * @param ref - Действие, которое требуется выполнить при успешной сортировке
 */

const sortingListItems = (location, action, elementID, keyElement, ref) => {
    let facts = new FormData()
    facts.append("action", action)
    facts.append("class", location)
    facts.append("key", keyElement)
    facts.append('hash', hashController.setHash(facts))

    sender.post(`/home/position/${elementID}`, facts)
        .then(response => {
            console.log(response)
            ref()
        })
        .catch(response => console.log("Ошибка сортировки"))
}

export default sortingListItems