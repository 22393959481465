import {observer} from "mobx-react-lite";
import {Row, Col, Select, Divider, Button, Space, Breadcrumb, Input, Image, Tag, Table, Switch} from "antd"
import React, { useEffect } from "react"
import orderController from "../order.controller"
import "../order.less"
import { useTranslation } from "react-i18next";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import {useParams} from "react-router-dom";

const OrderOrderHeader = observer(({order, statuses}) => {

    const { t } = useTranslation();

    return(
        <>
            <div className="order_header">
                <Row gutter={[15, 15]}>
                    <Col md={{ span: 6 }}>
                        <p className="sub">{t('order:statusOrder')}</p>

                        <Select
                            defaultValue={order.status.id}
                            style={{ width: `100%` }}
                            onChange={e => orderController.setOrder(e)}
                        >
                            {statuses.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                        </Select>
                    </Col>
                    <Col md={{ span: 6 }}>
                        <p className="sub">{t('order:statusPay')}</p>

                        { order.paystatus }
                    </Col>
                    <Col md={{ span: 6 }}>
                        <p className="sub">{t('order:type')}</p>

                        { order.typepay }
                    </Col>
                    <Col md={{ span: 6 }}>
                        <p className="sub">Трек-номер</p>
                        <Input
                            onChange={(e) => orderController.order.tracknumber = e.target.value}
                            value={orderController.order.tracknumber}
                            disabled={orderController.order.status.id !== 5}
                        />
                    </Col>
                    <Col md={{ span: 6 }}>
                        <p className="sub">Создавать сделку</p>
                        <Switch
                            onChange={(e) => orderController.order.dealactive = e}
                            checked={orderController.order.dealactive}
                        />
                    </Col>
                    <Col md={{ span: 6 }}>
                        <p className="sub">Разрешено закрытие сделки</p>
                        <Switch
                            onChange={(e) => orderController.order.dealcloseallowed = e}
                            checked={orderController.order.dealcloseallowed}
                        />
                    </Col>
                    <Col md={{ span: 6 }}>
                        <p className="sub">Разрешен ли запрос на выплату?</p>
                        <Switch
                            onChange={(e) => orderController.order.paymentsellerallowed = e}
                            checked={orderController.order.paymentsellerallowed}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
})

const OrderOrder = observer(() => {
    let { orderID } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        orderController.getOrderByID(orderID)
        orderController.getStatuses()
    }, [])


    const columns = [
        {
            title: t('order:picture'),
            dataIndex: 'picture',
            key: 'picture',
            render: (text, record) => {
                let pictureItem = record.item.photo;

                if(record.sub) {
                    if(record.sub.item.photo !== null && record.sub.item.photo.length > 0) {
                        pictureItem = record.sub.item.photo[0].path;
                    }
                }

                return(
                    <Image
                        width={'100px'}
                        src={pictureItem}
                    />
                )
            }
        },
        {
            title: t('order:name'),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                let nameItem = record.item.name;
                if(record.sub) {
                    nameItem = record.sub.item.name;
                }

                return <a href={`/shop/catalog/product/${record.item.id}`} target="_blank">{nameItem}</a>;
            }
        },
        {
            title: 'Артикул',
            dataIndex: 'id_crm',
            key: 'id_crm',
            render: (text, record) => {
                let nameItem = record.item.id_crm;
                if(record.sub) {
                    nameItem = record.sub.item.id_crm;
                }

                return nameItem
            }
        },
        {
            title: t('order:props'),
            key: 'propery',
            render: (text, record) => <Tag>{text.filtername}</Tag>
        },
        {
            title: t('order:count'),
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: t('order:price'),
            dataIndex: 'price',
            key: 'picture',
            render: (text, record) => {
                let priceItem = record.price;

                if(record.sub) {
                    priceItem = record.sub.item.price;
                }

                return priceItem;
            }
        },
        {
            title: 'Продавец',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => {
                return(
                    <>
                        <p><a href={`/users/${record.item.user.id}`} target="_blank">{record.item.user.firstname} {record.item.user.lastname}</a><br />{record.item.user.email}<br />{record.item.user.phone}</p>
                    </>
                )
            }
        },
    ];



    return(
        <>
            {orderController.order &&
                <>
                    <Row justify="space-around" align="middle" className="title_page">
                        <Col span={24}>
                            <Breadcrumb>
                                <Breadcrumb.Item href={"/"}>{t('order:main')}</Breadcrumb.Item>
                                <Breadcrumb.Item href={"/shop"}>{t('order:shop')}</Breadcrumb.Item>
                                <Breadcrumb.Item href={"/shop/orders"}>{t('order:orders')}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t('order:view')}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col md={{ span: 12 }}>
                            <h1>Заказ #{orderController.order.id}</h1>
                        </Col>
                        <Col md={{ span: 12 }} className="right">
                            <Space>
                                <Button type="primary" onClick={() => orderController.setStatusByOrderID()}>Сохранить</Button>
                            </Space>
                        </Col>
                    </Row>
                    <OrderOrderHeader order={orderController.order} statuses={orderController.statuses} />
                    <Row gutter={[20]}>
                        <Col md={{ span: 12 }}>
                            <div className="order_header">
                                <p className="sub">Покупатель</p>

                                <Row gutter={[10, 20]}>
                                    <Col span={12}><p className="subTitle">ФИО:</p></Col>
                                    <Col span={12}>{ orderController.order.user.firstname } { orderController.order.user.lastname } { orderController.order.user.surname }</Col>

                                    <Col span={12}><p className="subTitle">Телефон:</p></Col>
                                    <Col span={12}>{orderController.order.user.phone}</Col>

                                    <Col span={12}><p className="subTitle">Email:</p></Col>
                                    <Col span={12}>{orderController.order.user.email}</Col>
                                </Row>
                            </div>

                            {orderController.order.recipient && orderController.order.recipient > 0 ?
                                <>
                                    <div className="order_header recipient">
                                        <p className="sub">Получатель</p>

                                        <Row gutter={[10, 20]}>
                                            <Col span={12}><p className="subTitle">Имя:</p></Col>
                                            <Col span={12}>
                                                { orderController.order.recipient_name }
                                            </Col>

                                            <Col span={12}><p className="subTitle">Телефон:</p></Col>
                                            <Col span={12}>{orderController.order.recipient_phone}</Col>
                                        </Row>
                                    </div>
                                </>
                                : <></>
                            }

                            <div className="order_header">
                                <p className="sub">Доставка</p>

                                <Row gutter={[10, 20]}>
                                    <Col span={12}><p className="subTitle">Тип доставки</p></Col>
                                    <Col span={12}>{ orderController.order.typedelivery }</Col>

                                    {orderController.order.delivery !== "default" &&
                                        <>
                                            <Col span={12}><p className="subTitle">Способ доставки</p></Col>
                                            <Col span={12}>
                                                {orderController.order.delivery === 'sdek' &&
                                                <>{t('order:sdek')}</>
                                                }
                                                {orderController.order.delivery === 'boxbery' &&
                                                <>Boxbery</>
                                                }
                                                {orderController.order.delivery === 'pochta' &&
                                                <>Почта РФ</>
                                                }
                                            </Col>
                                        </>
                                    }

                                    {orderController.order.typedelivery !== "Самовывоз" && orderController.order.delivery !== 'sdek' && orderController.order.delivery !== 'boxbery' && orderController.order.delivery !== 'pochta' &&
                                        <>
                                            <Col span={12}><p className="subTitle">Дата доставки</p></Col>
                                            <Col span={12}>{ orderController.order.customDateDelivery.date }</Col>

                                            <Col span={12}><p className="subTitle">Интервал доставки</p></Col>
                                            <Col span={12}>{ orderController.order.customDateDelivery.time }</Col>
                                        </>
                                    }


                                    <Col span={12}><p className="subTitle">Адрес доставки/самовывоза</p></Col>
                                    <Col span={12}>{orderController.order.address}</Col>

                                    <Col span={12}><p className="subTitle">Полный адрес</p></Col>
                                    <Col span={12}>{orderController.order.fulladdress}</Col>

                                    {(orderController.order.privatehome && orderController.order.privatehome !== "") ?
                                        <>
                                            <Col span={12}><p className="subTitle">
                                                Частный дом
                                            </p></Col>
                                            <Col span={12}>{ orderController.order.privatehome ? 'Да' : 'Нет' }</Col>
                                        </> : <></>
                                    }

                                    {orderController.order.zip !== "" ?
                                        <>
                                            <Col span={12}><p className="subTitle">Индекс</p></Col>
                                            <Col span={12}>{ orderController.order.zip }</Col>
                                        </> : <></>
                                    }

                                    {orderController.order.apartment !== "" ?
                                        <>
                                            <Col span={12}><p className="subTitle">Квартира / Офис</p></Col>
                                            <Col span={12}>{ orderController.order.apartment }</Col>
                                        </> : <></>
                                    }

                                    {orderController.order.entrance !== "" ?
                                        <>
                                            <Col span={12}><p className="subTitle">Подъезд</p></Col>
                                            <Col span={12}>{ orderController.order.entrance }</Col>
                                        </> : <></>
                                    }

                                    {orderController.order.floor !== "" ?
                                        <>
                                            <Col span={12}><p className="subTitle">Этаж</p></Col>
                                            <Col span={12}>{ orderController.order.floor }</Col>
                                        </> : <></>
                                    }
                                </Row>
                            </div>

                            {orderController.order.comment !== "" || Number(orderController.order.changefrom) > 0 ?
                                <div className="order_header">
                                    <p className="sub">Дополнительно</p>

                                    <Row gutter={[10, 20]}>
                                        {Number(orderController.order.changefrom) > 0 &&
                                            <>
                                                <Col span={12}><p className="subTitle">Сдача с суммы:</p></Col>
                                                <Col span={12}>{orderController.order.changefrom}</Col>
                                            </>
                                        }
                                        {orderController.order.comment !== "" &&
                                            <Col span={24}>
                                                <p className="subTitle">Комментарий</p>
                                                <p>{orderController.order.comment}</p>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                                : <></>
                            }

                            {orderController.deal
                                ? <div className="order_header">
                                    <p className="sub">Откуда забрать товар</p>

                                    <Row gutter={[10, 20]}>
                                        <Col span={12}><p className="subTitle">Адрес</p></Col>
                                        <Col span={12}>{orderController.deal.fulladdress}</Col>

                                        <Col span={12}><p className="subTitle">Индекс</p></Col>
                                        <Col span={12}>{orderController.deal.zip}</Col>

                                        <Col span={12}><p className="subTitle">Подьезд</p></Col>
                                        <Col span={12}>{orderController.deal.entrance}</Col>

                                        <Col span={12}><p className="subTitle">Этаж</p></Col>
                                        <Col span={12}>{orderController.deal.floor}</Col>

                                        <Col span={12}><p className="subTitle">Квартира</p></Col>
                                        <Col span={12}>{orderController.deal.apartment}</Col>

                                        <Col span={12}><p className="subTitle">Координаты</p></Col>
                                        <Col span={12}>{orderController.deal.latitude} - {orderController.deal.longitude}</Col>

                                        <Col span={12}><p className="subTitle">Частный дом</p></Col>
                                        <Col span={12}>{orderController.deal.privatehome ? 'Да' : 'Нет'}</Col>

                                        <Col span={12}><p className="subTitle">Имя</p></Col>
                                        <Col span={12}>{orderController.deal.fio}</Col>

                                        <Col span={12}><p className="subTitle">Телефон</p></Col>
                                        <Col span={12}>{orderController.deal.phone}</Col>

                                        <Col span={12}><p className="subTitle">Комментарий</p></Col>
                                        <Col span={12}>{orderController.deal.comment}</Col>
                                    </Row>
                                    <div className="order_map">
                                        <YMaps>
                                            <Map
                                                defaultState={{ center: [orderController.deal.latitude, orderController.deal.longitude], zoom: 15 }}
                                                width="100%"
                                                height="400px"
                                            >
                                                <Placemark geometry={[orderController.deal.latitude, orderController.deal.longitude]} />
                                            </Map>
                                        </YMaps>
                                    </div>
                                </div>
                                : <></>
                            }
                        </Col>
                        <Col md={{ span: 12 }}>
                            <div className="order_header">
                                <p className="sub">Стоимость</p>
                                <Row gutter={[10, 20]}>
                                    <Col span={12}><p className="subTitle">Дата создания</p></Col>
                                    <Col span={12}>{orderController.order.created_at}</Col>
                                    <Col span={12}><p className="subTitle">Товаров ({orderController.order.countAll})</p></Col>
                                    <Col span={12}>{orderController.order.priceProducts}</Col>
                                    {orderController.order.paydelivery > 0 &&
                                        <>
                                            <Col span={12}><p className="subTitle">Доставка</p></Col>
                                            <Col span={12}>{orderController.order.paydelivery}</Col>
                                        </>
                                    }

                                    {/*<Col span={12}><p className="subTitle">Скидка</p></Col>*/}
                                    {/*<Col span={12}></Col>*/}
                                    <Divider dashed />
                                    <Col span={12}><p className="subTitle bold">Итого:</p></Col>
                                    <Col span={12}>{orderController.order.summ}</Col>
                                </Row>
                            </div>
                            <div className="order_map">
                                <YMaps>
                                        <Map
                                            defaultState={{ center: [orderController.order.latitude, orderController.order.longitude], zoom: 15 }}
                                            width="100%"
                                            height="400px"
                                        >
                                            <Placemark geometry={[orderController.order.latitude, orderController.order.longitude]} />
                                        </Map>
                                </YMaps>
                            </div>

                        </Col>
                        <Col span={24}>
                            <div className="order_header">
                                <p className="sub">Состав заказа</p>

                                <Table columns={columns} dataSource={orderController.order.products} pagination={false} />
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </>
    )
})

export default OrderOrder