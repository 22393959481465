import { observer } from "mobx-react-lite"
import deleteFile from "../../../../../store/deleteFile.controller";
import _main from "../_main.screen.controller"
import { Tooltip, Button } from "antd"
import {DeleteOutlined} from "@ant-design/icons";

const deleteImageByType = (path, type, id) => {
    console.log(id)
    deleteFile(type, path, id).then(_ => {
        _main.get()

    })
}

const DeleteImage = observer(({ path, type, id=0 }) => {

    return(
        <Tooltip title="Удалить">
            <Button
                danger
                type="dashed"
                className="picture-delete"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => deleteImageByType(path, type, id)}
            />
        </Tooltip>
    )
})

export default DeleteImage