import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {Breadcrumb, Button, Checkbox, Col, Input, Row, Select, List, Tooltip, Alert, Upload, Tabs} from "antd";
import {DeleteOutlined, InboxOutlined} from "@ant-design/icons";
import getLoader from "../../../../store/uploadFiles.controller";
import React, { useEffect } from "react";
import categoryController from "../controllers/category.controller"
import TemplateTitle from "../../../template/title/TemplateTitle";
import pc from "../../properties/controllers/properties.controller";

const ShopCategoryEditedMain = observer(() => {

    const { t } = useTranslation();

    return(
        <>
            <Row gutter={16}>
                <Col span={24}>
                    <Checkbox
                        checked={Number(categoryController.category.view) > 0 ? true : false}
                        onChange={(e) => categoryController.setCategory("view", e.target.checked ? 1 : 0)}
                    >
                        {t('category:active')}
                    </Checkbox>
                </Col>
                <Col span={12} className="gutter-row">
                    <label htmlFor="name" className="all-label">{t('category:name')}</label>
                    <Input
                        value={categoryController.category.name}
                        id="name"
                        onChange={(e) => categoryController.setCategory("name", e.target.value)}
                        required
                    />
                </Col>
                <Col span={12} className="gutter-row">
                    <label htmlFor="search" className="all-label">Текст для поиска категории</label>
                    <Input
                        value={categoryController.category.search}
                        id="search"
                        onChange={(e) => categoryController.setCategory("search", e.target.value)}
                    />
                </Col>
                <Col span={24} className="gutter-row">
                    <label htmlFor="allproduct" className="all-label">Название под все товары</label>
                    <Input
                        value={categoryController.category.allproduct}
                        id="allproduct"
                        onChange={(e) => categoryController.setCategory("allproduct", e.target.value)}
                    />
                </Col>
                <Col span={24} className="gutter-row">
                    <label htmlFor="text" className="all-label">{t('category:description')}</label>
                    <Input.TextArea
                        value={categoryController.category.text}
                        id="text"
                        style={{height:'220px'}}
                        onChange={(e) => categoryController.setCategory("text", e.target.value)}
                    />
                </Col>
            </Row>
        </>
    )
})

const ShopCategoryEditedImage = observer(() => {

    const { t } = useTranslation();

    const updateData = (value) => {
        let data = categoryController.category.picture && categoryController.category.picture.length > 0 ? categoryController.category.picture : []
        data.push(value)
        categoryController.setCategory("picture", data[0])
    }

    return(
        <>
            <label className="all-label">{categoryController.category.picture ? t('category:replacement') : t('category:loading')}</label>
            <Upload.Dragger
                multiple={false}
                fileList={[]}
                customRequest={e => getLoader(e, "images", "file", "category", updateData)}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('category:drop')}</p>
            </Upload.Dragger>
            <Col span={24} className="gutter-row">
                {categoryController.category.picture && categoryController.category.picture.path &&
                <>
                    <label className="all-label">{t('category:picture')}</label>
                    <div className="product-item_picture">
                        <img src={categoryController.category.picture.path} alt="" />
                        {categoryController.category.id && categoryController.category.id > 0 &&
                        <Tooltip title={t('category:delete')}>
                            <Button
                                danger
                                type="dashed"
                                className="picture-delete"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                onClick={() => categoryController.deletePicture(categoryController.category.picture.path)}
                            />
                        </Tooltip>
                        }
                    </div>
                </>
                }
            </Col>
        </>
    )
})

const ShopCategoryEditedTreeSelectButton = observer(({ id }) => {
    return(
        <>
            {
                Number(id) === Number(categoryController.category.parentid)
                    ? <button className="button-main_no-active-little active">Выбрано</button>
                    : <button className="button-main_no-active-little" onClick={() => categoryController.setCategory("parentid", id)}>Выбрать</button>
            }
        </>
    )
})

const ShopCategoryEditedTree = observer(({ categoryID }) => {

    return(
        <>
            <List
                itemLayout="horizontal"
                dataSource={categoryController.tree}
                renderItem={item => (
                    <>
                        {item.id !== Number(categoryID) &&
                            <List.Item>
                                <List.Item.Meta
                                    title={
                                        item.subcategory
                                            ? <p onClick={() => categoryController.getCategoryTree( item.id )}>{ item.name }</p>
                                            : <p>{ item.name }</p>
                                    }
                                />
                                <ShopCategoryEditedTreeSelectButton id={item.id} parent={categoryController.category.parentid} />
                            </List.Item>
                        }
                    </>
                )}
            />
        </>
    )
})

const ShopCategoryEditedFilterSelectButton = observer(({ id }) => {

        console.log(categoryController.category.filtercategory)

    return(
        <>
            {
                categoryController.category.filtercategory &&
                categoryController.category.filtercategory.length > 0 &&
                categoryController.category.filtercategory.findIndex(el => Number(el) === Number(id)) >= 0
                    ?
                    <button className="button-main_no-active-little active" onClick={() => categoryController.setCategory("filtercategory", id)}>Снять выбор</button>
                    :
                    <button className="button-main_no-active-little" onClick={() => categoryController.setCategory("filtercategory", id)}>Выбрать</button>
            }
        </>
    )
})

const ShopCategoryEditedFilter = observer(() => {

    return(
        <>
            <List
                itemLayout="horizontal"
                dataSource={pc.properties}
                renderItem={item => (
                    <>
                        <List.Item>
                            <List.Item.Meta
                                title={<p>{ item.name }</p>}
                            />
                            <ShopCategoryEditedFilterSelectButton id={item.id} />
                        </List.Item>
                    </>
                )}
            />
        </>
    )
})

const ShopCategoryEdited = observer(() => {

    let { categoryID, createID } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        categoryController.getCategoryByCategoryID(categoryID ? "category" : "create", categoryID ? categoryID : createID)
    }, [])

    return(
        <>

            <TemplateTitle
                title={t('category:edit')}
                breadcrumb={categoryController.breadcrumb}
                other={categoryController.category.breadcrumb}
                buttons={[
                    { type: "button", title: "Сохранить", ref: (e) => categoryController.postCategory(e), primary: true },
                    { type: "link", title: "Вернуться к списку", link: "/shop/catalog/category/" + categoryController.category.parentid }
                ]}
            />

            <Tabs defaultActiveKey="1" onChange={(e) => categoryController.setTabs(e)}>
                <Tabs.TabPane tab="Основное" key="main">
                    <ShopCategoryEditedMain />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Родительская категория" key="parent">
                    <ShopCategoryEditedTree categoryID={ categoryID } />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Изображение" key="image">
                    <ShopCategoryEditedImage />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Фильтры" key="filter">
                    <Alert
                        message="Внимание!"
                        description="Выбранные Вами характеристики будут действительны для товаров, которые находятся непосредственно в данной категории. Если в этой категории есть другие категории, то на товары внутри них действовать характеристики не будут."
                        type="warning"
                        closable
                    />
                    <ShopCategoryEditedFilter />
                </Tabs.TabPane>
            </Tabs>
        </>
    )
})

export default ShopCategoryEdited